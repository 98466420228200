html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

* {
  margin: 0;
  padding: 0;
}
body {
  font-family: $font;
  color: $normal;
  background: $lighter_normal;
}
html,
body {
  font-size: 90%;
  @include breakpoint(1100px) {
    font-size: 100%;
  }
}
a:link,
a:visited {
  text-decoration: none;
  outline: none;
}
a,
button,
.button,
.form-buttons input[type='submit'] {
  transition: all 0.15s ease-in;
  -moz-transition: all 0.15s ease-in;
  -webkit-transition: all 0.15s ease-in;
}
a img {
  border: none;
}
.hide {
  display: none;
}
.clearfix:after {
  content: ' ';
  display: block;
  height: 0;
  font-size: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  display: inline-block;
}
/* mac hide \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  text-rendering: optimizelegibility;
  line-height: normal;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 14px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 11px;
}

input[type='text'],
input[type='password'],
textarea {
  background: #fff;
  font-size: rem-calc(16);
  padding: 0 20px;
  font-family: $font;
  border: none;
  height: 40px;
  border-radius: 10px;
  color: $normal;
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: $normal;
  opacity: 1;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $normal;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $normal;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: $normal;
  opacity: 1;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent !important;
}
input:focus::-moz-placeholder,
textarea:focus::-moz-placeholder {
  color: transparent !important;
}
input:focus:-moz-placeholder,
textarea:focus:-moz-placeholder {
  color: transparent !important;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.hidden {
  opacity: 0;
}
.visible {
  opacity: 1;
}
.fit-height {
  @include breakpoint(800px) {
    height: 100vh;
  }
}
.table-display {
  display: table;
}
.center-valign {
  display: table-cell;
  vertical-align: middle;
}

.bg-innline {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

@include between-breakpoints(100px, 768px) {
  .column,
  .columns {
    padding-left: 10px;
    padding-right: 10px;
  }
  .row .row {
    margin: 0 -10px;
  }
}

.thumb {
  img {
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}

.button,
.form-buttons input[type='submit'] {
  display: inline-block;
  font: italic 700 rem-calc(16) $font;
  color: #fff;
  padding: 9px 20px 10px;
  border: none;
  background-color: $normal;
  border-radius: 30px;
  &:hover {
    background: darken($normal, 5%);
  }
  &.yellow {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0.05, #feeb19),
      color-stop(1, #e8d503)
    );
    background: -moz-linear-gradient(top, #feeb19 5%, #e8d503 100%);
    background: -webkit-linear-gradient(top, #feeb19 5%, #e8d503 100%);
    background: -o-linear-gradient(top, #feeb19 5%, #e8d503 100%);
    background: -ms-linear-gradient(top, #feeb19 5%, #e8d503 100%);
    background: linear-gradient(to bottom, #feeb19 5%, #e8d503 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#feeb19', endColorstr='#e8d503',GradientType=0);
    background-color: #feeb19;
    color: $normal;
    &:hover {
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0.05, #e8d503),
        color-stop(1, #feeb19)
      );
      background: -moz-linear-gradient(top, #e8d503 5%, #feeb19 100%);
      background: -webkit-linear-gradient(top, #e8d503 5%, #feeb19 100%);
      background: -o-linear-gradient(top, #e8d503 5%, #feeb19 100%);
      background: -ms-linear-gradient(top, #e8d503 5%, #feeb19 100%);
      background: linear-gradient(to bottom, #e8d503 5%, #feeb19 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e8d503', endColorstr='#feeb19',GradientType=0);
      background-color: #e8d503;
    }
  }
  &.normal {
    background: none;
    font-weight: normal;
    border: $light_normal 1px solid;
    color: $normal;

    &:hover {
      background-color: $normal;
      border-color: $normal;
      color: #fff;
    }
  }
  &.left {
    float: left;
  }
  &.right {
    float: right;
  }
  &.new_style {
    border-radius: 5px;
    background: none;
    background-color: #f2c900;
    display: inline-block;
    font: normal normal rem-calc(20) $font;
    padding: 15px 20px;
    border: none;
    color: $normal;
    &:hover {
      background-color: #e5bd09;
    }
  }
}

.title-page {
  font-size: rem-calc(36);
  margin: rem-calc(0 0 40px);
  span {
    color: #e0cd00;
  }
}

header {
  padding: 20px 5px;
  background-color: $normal;
  border-bottom: #545f77 5px solid;
  position: relative;
  z-index: 2222;
  @include breakpoint(768px) {
    padding: 30px 20px;
    border-bottom-width: 10px;
  }
  #logo {
    display: block;
    float: left;
    img {
      display: block;
      max-height: 17px;
      transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -webkit-transition: all 0.4s ease;
      margin-top: 5px;
      @include breakpoint(768px) {
        max-height: 30px;
        margin-top: 0;
      }
    }
  }

  .search-form {
    bottom: -7px;
    margin: 5px auto 0;

    @include breakpoint(540px) {
      display: block;
      margin: -10px auto 0;
    }
  }
  .menu-right {
    padding-top: 0;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
  }

  &.fixed-nav {
    position: fixed;
    width: 100%;
    z-index: 2222;
    top: 0;
    left: 0;
    padding: 20px 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    @include breakpoint(768px) {
      padding: 38px 0;
    }
    &.tiny {
      padding: 10px 0;
      @include breakpoint(768px) {
        padding: 10px;
      }
    }
  }

  .medium-12 {
    position: relative;
  }
  .breadcrumbs-in-banner {
    display: none;
    position: absolute;
    left: 20px;
    bottom: -100px;
    width: 100%;
    @include breakpoint(768px) {
      display: block;
    }
  }
}

#middle {
  clear: both;
  position: relative;
  min-height: 300px;
  z-index: 222;
  padding: 200px 0 30px 0;
  &.less-height {
    padding-top: 90px;
  }
  .profiel & {
    padding: 157px 0 0 0;
  }
}
.section-content {
  position: relative;
  z-index: 22;
}

.form-elements {
  .row-form {
    &.full-top {
      margin-bottom: 30px;
    }
    label {
      font-size: rem-calc(22);
      display: block;
      color: #fff;
      font-weight: normal;
    }
    input[type='text'],
    input[type='password'],
    textarea {
      height: 50px;
      max-width: 500px;
    }
    textarea {
      height: 200px;
      max-width: 100%;
    }
    &.form-50 {
      width: 48%;
      margin-left: 4%;
      float: left;
    }
    &.nomar {
      margin-left: 0;
    }
  }
}

.social-icon {
  font-size: 0;
  a {
    border-radius: 50%;
    display: inline-block;
    font-size: 20px;
    background: #1e5fa9;
    color: #fff;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin-right: 7px;
    cursor: pointer;
    &:hover {
      background: darken(#1e5fa9, 10%);
    }
    &.fb {
      i {
        position: relative;
        top: 2px;
      }
    }
    &.tw {
      background: #6dd4f9;
      &:hover {
        background: darken(#6dd4f9, 10%);
      }
    }
    &.li {
      background: #1386b7;
      &:hover {
        background: darken(#1386b7, 10%);
      }
    }
    &.gp {
      font-size: 16px;
      background: #eb5b4a;
      &:hover {
        background: darken(#eb5b4a, 10%);
      }
    }
    &.wa {
      background: #00a962;
      font-size: 22px;
      &:hover {
        background: darken(#00a962, 10%);
      }
    }
  }
}

.search-form {
  max-width: 250px;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  position: relative;
  @include breakpoint(1024px) {
    max-width: 370px;
  }
  input[type='text'] {
    float: left;
    width: 65%;
    height: 40px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    @include breakpoint(768px) {
      width: 75%;
    }
  }
  .button {
    min-height: 40px;
    text-transform: uppercase;
    font-style: normal;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 10px 10px 0;
    width: 35.42%;
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
    @include breakpoint(768px) {
      width: 25.42%;
    }
    &.yellow {
      background: $action;
      color: $normal;
      float: right;
      &:hover {
        background: darken($action, 5%);
      }
    }
  }
}

.whitebox {
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(60, 71, 94, 0.15);
}
.bluebox {
  background-color: #daeaf1;
}

.box-content {
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(60, 71, 94, 0.15);
  padding: 20px;
  margin-bottom: 20px;
  @include breakpoint(1024px) {
    padding: 40px;
    margin-bottom: 40px;
  }
  &.haflpadd {
    padding: 40px 20px;
  }
}

.list-item-sidebar {
  list-style: none;
  border-top: #f1f1f1 1px solid;
  padding-top: 20px;
  li {
    background: #f9f5cc;
    padding: 15px 20px 30px;
    margin-bottom: 20px;
    .date {
      font-size: 14px;
      margin-bottom: 8px;
      i {
        margin-right: 7px;
        font-size: 12px;
      }
    }
    .title {
      font-size: 14px;
      line-height: 18px;
      @include breakpoint(780px) {
        font-size: 18px;
        line-height: 20px;
      }
      a {
        color: $normal;
      }
    }
  }
}

.widget {
  position: relative;
  h2 {
    font-size: rem-calc(32);
    line-height: rem-calc(38);
    margin-bottom: rem-calc(30);
    font-weight: 700;
    @include breakpoint(1024px) {
      font-size: rem-calc(36);
      line-height: rem-calc(40);
    }
  }
  p {
    margin-bottom: rem-calc(30);
  }
}
.widget-01 {
  .text,
  h2 {
    a,
    span {
      color: $action;
    }
  }
}
.widget-02 {
  font-size: rem-calc(18);
  line-height: rem-calc(30);
}
.widget-03 {
  .form-field {
    label {
      font-style: italic;
      display: block;
      margin-bottom: 10px;
      font-size: rem-calc(18);
    }
    input[type='text'] {
      border-radius: 5px;
      box-shadow: inset 0 0 5px 0 rgba(60, 71, 94, 0.25);
      width: 100%;
      min-height: 50px;
      margin-bottom: 20px;
    }
  }
  .form-buttons input[type='submit'] {
    background: $action;
    color: $normal;
    &:hover {
      background: darken($action, 5%);
    }
  }
}
.widget-04 {
  margin-top: 30px;
  @include breakpoint(768px) {
    margin-top: 0;
  }
  h2 {
    margin-bottom: 10px;
    font-family: $rubik-font;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
  }
  p {
    margin-bottom: 23px;
    font-size: 18px;
    line-height: 28px;
  }
  .inner-body {
    padding: 25px 30px 0;
  }
  .form {
    padding-bottom: 40px;
  }
  textarea {
    margin-bottom: 5px;
    padding: 10px 20px;
    width: 100%;
    height: 160px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }
  input {
    box-shadow: none;
    margin-bottom: 0;
    width: 100%;
    height: 50px;
    border-radius: 0;
    border: 1px solid #f1f1f1;
    font-size: 18px;
    font-weight: 300;
    line-height: 28px;
  }
  input[type='submit'] {
    color: #fff;
    padding: 11px 36px 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    background-color: #00a9f0;
    border-radius: 4px;
    border: none;
    &:hover {
      color: #fff;
      background-color: darken(#00a9f0, 5%);
    }
  }
  textarea,
  input[type='text']::-webkit-input-placeholder {
    color: #3c475e !important;
  }

  textarea,
  input[type='text']::-ms-input-placeholder {
    color: #3c475e !important;
  }

  textarea,
  input[type='text']::-moz-placeholder {
    color: #3c475e !important;
  }

  textarea,
  input[type='text']::-moz-placeholder {
    color: #3c475e !important;
  }
  .submit {
    margin-top: 10px;
  }
  .fieldset {
    border: none;
  }
  .image-bottom {
    text-align: center;
  }
}
//
.widget-search {
  margin-top: 30px;
  margin-bottom: 30px;
  @include breakpoint(768px) {
    margin-top: 0;
  }
  form {
    position: relative;
  }
  input {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    font-size: 18px;
    line-height: 28px;
    padding: 0 30px;
  }
  input[type='text']::-webkit-input-placeholder {
    color: #adb5c6 !important;
  }

  input[type='text']::-ms-input-placeholder {
    color: #adb5c6 !important;
  }

  input[type='text']::-moz-placeholder {
    color: #adb5c6 !important;
  }

  input[type='text']::-moz-placeholder {
    color: #adb5c6 !important;
  }
  a.sticky-search-btn {
    position: absolute;
    right: 30px;
    bottom: 10px;
    i {
      background-position: 0 -150px;
      width: 20px;
      height: 20px;
    }
  }
  span {
    font-weight: bold;
    margin: 10px 0;
    font-size: 20px;
  }
}
// widget Q&A
.widget-qanda {
  h3 {
    padding-bottom: 20px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    line-height: 31px;
    border-bottom: 1px solid #f1f1f1;
  }
}

.qanda-list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin-bottom: 10px;
    h4 {
      margin-bottom: 17px;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
    }
    p {
      font-size: 18px;
      line-height: 23px;
    }
    span.answer {
      position: relative;
      display: block;
      margin-bottom: 7px;
      font-size: 14px;
      font-style: italic;
      font-weight: 600;
      line-height: 18px;
      .fa {
        padding-right: 8px;
      }
    }
  }
  .qanda-item {
    position: relative;
    max-height: 260px;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      bottom: 20px;
      left: 1px;
      right: 0;
      width: 100%;
      display: block;
      height: 62px;
      background: linear-gradient(
        0deg,
        #daeaf1 0%,
        rgba(218, 234, 241, 0) 100%
      );
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 1px;
      right: 0;
      width: 100%;
      display: block;
      height: 20px;
      background-color: #daeaf1;
    }
    .less-meer {
      padding-bottom: 17px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      background-color: #daeaf1;
      text-align: center;
      a {
        position: relative;
        z-index: 1;
        font-size: 14px;
        line-height: 18px;
        color: #3c475e;
        cursor: pointer;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;
        width: 100%;
        display: block;
        height: 62px;
        background: linear-gradient(
          0deg,
          #daeaf1 0%,
          rgba(218, 234, 241, 0) 100%
        );
      }
    }
    &.active {
      max-height: inherit;
      &:after {
        background: none;
        height: 0;
        position: inherit;
      }
      .less-meer {
        display: none;
      }
    }
  }
  .qanda-box-register {
    h4 {
      margin-top: 4px;
      margin-bottom: 8px;
      font-size: 30px;
      font-weight: bold;
      line-height: 38px;
      text-align: center;
    }
    p {
      margin-bottom: 25px;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
    }
  }
  .qanda-box-link {
    .inner-body {
      padding-top: 22px;
      margin-bottom: 28px;
    }
    h4 {
      margin-bottom: 28px;
    }
  }
  .inner-body {
    background-color: #daeaf1;
    padding: 16px 20px;
  }
  a.button {
    width: 100%;
    color: #fff;
    padding: 11px 36px 12px;
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    line-height: 27px;
    text-align: center;
    background-color: #00a9f0;
    border-radius: 4px;
    &:hover {
      color: #fff;
      background-color: darken(#00a9f0, 5%);
    }
  }
  .login-box {
    position: relative;
    width: 200px;
    margin: 0 auto;
    margin-top: 21px;
    margin-bottom: 13px;
    padding-top: 3px;
    padding-left: 55px;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 45px;
      height: 45px;
      background-color: #fff;
      background-image: url(../images/icon-sprites.png);
      background-repeat: no-repeat;
      background-position: 17px -239px;
      border-radius: 50%;
    }
  }
}
.widget-qanda-line {
  @include breakpoint(768px) {
    .qanda-list {
      li {
        width: 33.33333%;
        display: inline-block;
        float: left;
      }
    }
  }
  h3 {
    margin-bottom: 13px;
    padding-bottom: 0;
    border: none;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }
  li {
    & + li {
      padding-left: 1px;
    }
  }
  .qanda-box-register {
    h4 {
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 28px;
      line-height: 36px;
    }
    p {
      margin-bottom: 10px;
    }
    .inner-body {
      padding-top: 12px;
    }
    .login-box {
      margin-top: 19px;
      margin-bottom: 4px;
    }
  }
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
/*CContent*/
.content-editor {
  font-size: rem-calc(18);
  line-height: rem-calc(30);
  color: $normal;
  font-weight: 400;
  .desc {
    font-size: rem-calc(20);
    line-height: rem-calc(30);
    color: #5e5e5e;
    font-weight: 700;
    margin-bottom: 30px;
  }

  a {
    color: $normal;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }

  h2 {
    font-size: rem-calc(40);
  }
  h3,
  h4,
  h5 {
    font-size: rem-calc(25);
    margin-bottom: 5px;
    font-weight: 700;
  }
  p {
    margin-bottom: 25px;
  }
  .thumb img {
    display: block;
    margin-bottom: 25px;
  }
  ul,
  ol {
    margin-left: 35px;
    margin-bottom: 25px;
    li {
      margin-bottom: 3px;
    }
  }
  table {
    margin-bottom: 25px;
    th {
      font-weight: normal;
      background: #f9f9f9;
      text-transform: uppercase;
    }
    td,
    th {
      text-align: left;
      padding: 10px;
      border: 1px solid #f1f1f1;
    }
  }
  blockquote {
    margin: 0 0 25px;
    position: relative;
    display: block;
    color: $action;
    padding: 20px 0;
    font-size: rem-calc(35);
    line-height: rem-calc(50);
    @include breakpoint(768px) {
      left: -100px;
    }
    @include breakpoint(1025px) {
      font-size: rem-calc(45);
      line-height: rem-calc(60);
    }
    @include breakpoint(1300px) {
      padding: 30px 0;
    }
    p {
      margin-bottom: 0;
    }
  }
  .alignright {
    float: right;
    margin: 0 0 15px 15px;
  }
  .alignleft {
    float: left;
    margin: 0 15px 15px 0;
  }
  .button {
    color: #ffffff;
    text-decoration: none;
  }
}

#footer-wrapper {
  clear: both;
  overflow: hidden;
  width: 100%;
  position: relative;

  padding: 30px 0 40px;
  border-top: $action 10px solid;

  .info-left {
    font-size: 18px;
    margin-bottom: 30px;
    @include breakpoint(600px) {
      float: left;
      min-width: 70%;
    }
    .over {
      overflow: hidden;
      span {
        display: inline-block;
        min-width: 90px;
      }
    }
    .logo-footer {
      font-weight: bold;
      color: $normal;
      @include breakpoint(480px) {
        float: left;
        margin-right: 22px;
      }
    }
    a {
      color: $normal;
    }
  }
  .social-footer {
    font-size: rem-calc(0);
    @include breakpoint(600px) {
      text-align: right;
      float: right;
      min-width: 30%;
    }
    a {
      color: $normal;
      display: inline-block;
      margin: 0 10px 0 0;
      @include breakpoint(600px) {
        margin: 0 0 0 10px;
      }
      .fa-twitter-square {
        color: #55a8ec;
      }
      .fa-facebook-square {
        color: #4966a0;
      }
      .fa-linkedin-square {
        color: #0177b5;
      }
    }

    i.fa {
      font-size: rem-calc(30);
      @include breakpoint(1300px) {
        font-size: rem-calc(32);
      }
    }
  }
}

/* New search bar */

@media only screen and (min-width: 1024px) {
  .search-block {
    display: none;
  }
}

.banner-intro {
  &.new-searchbar {
    height: 50vh;
    min-height: 520px;

    @include breakpoint(768px) {
      height: 55vh;
      min-height: 640px;
    }

    @include breakpoint(1400px) {
      height: 95vh;
    }

    .banner-intro__inner {
      transform: translate(-50%, -58%);

      @include breakpoint(768px) {
        transform: translate(-50%, -50%);
      }
    }

    .banner-intro__title {
      margin: 0 0 0px;

      @include breakpoint(768px) {
        margin: 0 0 30px;
      }
    }

    .banner-intro__desc {
      margin: 0 0 10px;

      @include breakpoint(768px) {
        margin: 0 0 20px;
      }
    }
  }
}

.board-block {
  &.new-searchbar {
    h2 {
      margin: 0 0 15px 0;
    }
    .board-block__scroll {
      margin-bottom: 0;

      @include breakpoint(768px) {
        margin-bottom: 30px;
      }

      .board-block__scroll-button {
        margin-bottom: 0;

        @include breakpoint(768px) {
          margin-bottom: 5px;
        }
      }
    }
  }
}

/* Sticky search form */

.sticky-search {
  position: absolute;
  bottom: -60px;
  left: 0;
  text-align: center;
  width: 100%;
  padding: 10px 15px;
  background-color: #545f77;
  .homepage & {
    display: none;
  }

  form {
    margin: 0 auto;
    position: relative;
    display: inline-block;

    input[type='text'] {
      font-size: 19px;
      width: 300px;
      padding-right: 70px;
      border-radius: 25px;
      height: 50px;
      width: 280px;
      @include breakpoint(768px) {
        width: 400px;
      }
    }

    input[type='text']::-webkit-input-placeholder {
      color: #adb5c5 !important;
    }

    input[type='text']::-ms-input-placeholder {
      color: #adb5c5 !important;
    }

    input[type='text']::-moz-placeholder {
      color: #adb5c5 !important;
    }

    input[type='text']::-moz-placeholder {
      color: #adb5c5 !important;
    }

    a.sticky-search-btn {
      position: absolute;
      right: 16px;
      bottom: 10px;

      .icon-search {
        background-position: 0px -150px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

#middle {
  &.new-searchbar {
    padding-top: 170px;
  }
}

.board-block__search-inner {
  &.new-searchbar {
    padding: 15px 0 10px 0;
  }
}

/* Quick test */
.banner-intro {
  &.quick-test {
    height: 72vh;

    .banner-intro__inner {
      @include breakpoint(745px) {
        min-width: 745px;
      }

      .banner-intro__title {
        @include breakpoint(1024px) {
          font-size: rem-calc(60);
        }
        span {
          color: #f4cb00;
        }
      }
    }
  }
}
.board-block {
  &.quick-test {
    background-color: #4b5464; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(
      transparent,
      #3c475e 10%
    ); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
      transparent,
      #3c475e 10%
    ); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
      transparent,
      #3c475e 10%
    ); /* For Firefox 3.6 to 15 */
    background: linear-gradient(transparent, #3c475e 10%); /* Standard syntax */

    .board-widget--large {
      &:nth-child(odd) {
        margin-top: 0;

        .board-widget__inner {
          @include breakpoint(1024px) {
            margin-top: -140px;
          }
        }
      }
    }

    .board-widget__desc {
      margin-bottom: 50px;
    }

    .option {
      &:first-child {
        margin-top: 30px;
      }

      a {
        border: 1px solid #3c475e;
        width: 100%;
        display: flex;
        padding: 10px 15px;
        align-items: center;
        color: #3c475e;
        margin-bottom: 15px;

        .option-order {
          font-size: 30px;
          margin-right: 10px;
          font-weight: bold;
        }
      }
      &:hover {
        background-color: #0096d6;
        a {
          color: #fff;
          cursor: pointer;
        }
      }
    }
    #correct_answer {
      border: 1px solid #3c475e;
      align-items: center;
      color: #3c475e;
      &:hover {
        background-color: transparent;
      }

      padding: 0;
      .header {
        color: #fff;
        padding: 3px 8px;
        background-color: #3c475e;
        width: 100%;
        clear: both;
        font-size: 12px;
      }
      .content {
        clear: both;
        width: 100%;
        padding: 10px 15px;
        .option-order {
          font-size: 30px;
          margin-right: 10px;
          font-weight: bold;
        }
        .explain {
          font-style: italic;
          padding: 10px 20px 16px 40px;
        }
      }
    }
  }
}

.banner-intro + .board-block {
  &.quick-test {
    margin-top: -90px;
    .board-widget--large {
      .board-widget__inner {
        margin: 0 !important;
      }
      .board-widget__link {
        margin-top: -100px;
      }
    }
  }
}

.board-block {
  &.test-result {
    background-color: #4b5464; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(
      transparent,
      #3c475e 10%
    ); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(
      transparent,
      #3c475e 10%
    ); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(
      transparent,
      #3c475e 10%
    ); /* For Firefox 3.6 to 15 */
    background: linear-gradient(transparent, #3c475e 10%); /* Standard syntax */

    .board-widget-new {
      width: 100%;
      text-align: right;
      position: relative;
    }

    .board-widget__image {
      @include breakpoint(1024px) {
        width: 80%;
      }
    }

    .board-widget__inner {
      text-align: left;

      @include breakpoint(1024px) {
        width: 45%;
        position: absolute;
        top: 80px;
      }
    }
  }
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  font-family: Roboto;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 50px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container[dir='rtl']
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}
.select2-container
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #e5e5e4;
  border-radius: 0;
  border-top: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #9f9f9f;
  line-height: 28px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir='rtl']
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--default[dir='rtl']
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  list-style: none;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--default[dir='rtl']
  .select2-selection--multiple
  .select2-selection__placeholder,
.select2-container--default[dir='rtl']
  .select2-selection--multiple
  .select2-search--inline {
  float: right;
}

.select2-container--default[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled
  .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role='group'] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled='true'] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected='true'] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir='rtl']
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir='rtl']
  .select2-selection--single
  .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__clear {
  display: none;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice {
  float: right;
}

.select2-container--classic[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open
  .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role='group'] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled='true'] {
  color: grey;
}

.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.form-main-sticky {
  padding-top: 150px;

  @include breakpoint(769px) {
    padding-top: 125px;
  }
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  font-family: Roboto;
  width: 100% !important;
}
.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 50px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container[dir='rtl']
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}
.select2-container
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}
.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #e5e5e4;
  border-radius: 0;
  border-top: 0;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #9f9f9f;
  line-height: 28px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir='rtl']
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--default[dir='rtl']
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  list-style: none;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--default[dir='rtl']
  .select2-selection--multiple
  .select2-selection__placeholder,
.select2-container--default[dir='rtl']
  .select2-selection--multiple
  .select2-search--inline {
  float: right;
}

.select2-container--default[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled
  .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role='group'] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled='true'] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected='true'] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}
.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir='rtl']
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir='rtl']
  .select2-selection--single
  .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__clear {
  display: none;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice {
  float: right;
}

.select2-container--classic[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir='rtl']
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open
  .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role='group'] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled='true'] {
  color: grey;
}

.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

//All styling for the cookiebar

#cookieBar {
  background: none repeat scroll 0 0 $lighter_normal;
  bottom: 0;
  color: white;
  display: none;
  float: left;
  font-family: Arial, Verdana, sans-serif;
  font-size: 13px;
  font-weight: bold;
  left: 0;
  opacity: 1;
  padding: 0;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 2000;
}
#cookieBar a {
  color: white !important;
  font-size: 13px;
}
#cookieBar .cont {
  width: 300px;
  @include breakpoint(768px) {
    width: 768px;
  }
  margin: 0 auto;
}
#cookieBar .cont div {
  float: left;
  margin: 10px 0;
}
#cookieBar .close {
  float: right !important;
  background-color: #0097d7;
  padding: 10px 25px;
  border-radius: 5px;
}
#cookieBar .notice {
  margin: 20px 0 !important;
}

#ouibounce-modal {
  font-family: Source Sans Pro, sans-serif;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  .underlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(84, 95, 119, 0.9);
    cursor: pointer;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
  }
  .modal {
    display: block;
    width: 100%;
    max-width: 820px;
    background-color: #fff;
    z-index: 1;
    position: absolute;
    margin: 0 auto;
    height: auto;
    padding: 10px;
    .image_column {
      text-align: center;
      @include breakpoint(768px) {
        text-align: left;
      }
    }
    @include breakpoint(768px) {
      margin: auto;
      max-height: 700px;
      height: 100vh;
      padding: 70px;
      img {
        position: absolute;
        margin-left: -25px;
      }
    }
    h2 {
      font-weight: 900;
      font-size: 40px;
      line-height: 35px;
      text-transform: uppercase;
      margin-bottom: 15px;
      color: #3c475e;
    }
    p {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 30px;
    }
    .form-form {
      textarea,
      input[type='text'],
      select {
        font-family: Roboto !important;
        border-radius: 0 !important;
        box-shadow: 0 0 0 !important;
        border: 1px solid #e5e5e4 !important;
        font-size: rem-calc(16) !important;
        padding: 0 10px !important;
        margin-bottom: 0 !important;
        min-height: 50px;
      }
      select {
        padding: 0 10px 0 6px !important;
      }
      textarea {
        height: 125px;
        padding: 10px !important;
      }
      .form-page {
        label {
          color: #000;
        }
      }
      input[type='submit'] {
      }
      .dropdown {
        .form-label {
          display: none;
        }
      }
      select {
        min-width: 276px !important;
        border: 1px solid #878787;
        font-size: 14px;
      }
      .message {
        width: 100%;
        font-size: 18px;
        padding: 15px;
      }
      .field {
        padding-bottom: 0;
        margin-bottom: 0;
      }
      .submit_button {
        margin: 20px 0;
        input {
          border-radius: 5px;
          background-color: #f2c900;
          display: inline-block;
          font: normal normal rem-calc(20) $font;
          padding: 15px 20px;
          border: none;
          color: $normal;
          &:hover {
            background-color: #e5bd09;
          }
        }
      }
    }
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-animation: popin 0.3s;
    animation: popin 0.3s;
  }
  .modal-footer {
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    a {
      cursor: pointer;
      text-decoration: none;
    }
  }
}
.form-controls-half {
  width: 50% !important;
}
