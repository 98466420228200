[class^="icon-"],
[class*=" icon-"] {
    background-image: url(../images/icon-sprites.png);
    background-repeat: no-repeat;
    display: inline-block;
}

.icon-user {
    width: 11px;
    height: 19px;
    background-position: 0 0;
}

.icon-user-hover {
    width: 11px;
    height: 19px;
    background-position: -50px 0;
}

.icon-search {
    width: 16px;
    height: 15px;
    background-position: 0 -50px;
}

.icon-search-hover {
    width: 16px;
    height: 15px;
    background-position: -50px -50px;
}

.icon-arrow {
    width: 30px;
    height: 16px;
    background-position: 0 -100px;
}

.icon-search-2 {
    width: 20px;
    height: 20px;
    background-position: 0 -150px;
}

.icon-check {
    width: 19px;
    height: 18px;
    background-position: 0 -200px;
}