$action: #f2c900;
@import 'icon-sprites';

.page-wrapper {
  overflow: hidden;
  background-color: #f1f1f1;
}

.header-page {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  .homepage & {
    background: 0;
  }

  padding: 15px;
  border: 0;
  transition-duration: 0.5s;

  .header-not-top & {
    background-color: $normal;
  }

  @include breakpoint(1024px) {
    .homepage & {
      padding: 50px;
    }

    .header-not-top & {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}

.header-logo {
  float: left;
  position: relative;
  z-index: 100;

  &__image {
    width: 160px;
    transition-duration: 0.5s;
  }

  @include breakpoint(1200px) {
    &__image {
      width: 239px;
      max-height: 70px;
      .homepage & {
        max-height: inherit;
        width: 299px;
      }
    }

    .header-not-top & {
      width: 239px;
    }
  }
}

.header-icons {
  float: right;
  position: relative;
  z-index: 100;
  &__item {
    width: 44px;
    height: 44px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    line-height: 44px;
    color: $action;
    border: 1px solid rgba($action, 0.25);
    font-size: 20px;
    border-radius: 50%;

    &:hover {
      background-color: $action;
      border-color: $action;
      color: $normal;

      .icon-user {
        width: 11px;
        height: 19px;
        background-position: -50px 0;
      }
    }

    &.loggedin {
      border-radius: 25px;

      @include breakpoint(1024px) {
        padding: 0 30px 0 17px;
        width: inherit;
        height: inherit;
        margin-top: -2px;
      }

      background-color: $action;
      border-color: $action;
      color: $normal;

      .icon-user {
        @include breakpoint(1024px) {
          position: absolute;
          top: 11px;
          right: 16px;
        }
        background-position: -50px 0;
      }
      &:hover {
        background-color: #fff;
        border-color: #fff;
      }
      .username {
        display: none;
        @include breakpoint(1024px) {
          display: block;
        }
      }
    }

    &--toggle-nav {
      position: relative;

      &:hover span {
        background-color: $normal;
      }

      span {
        width: 14px;
        height: 2px;
        background: $action;
        background-color: $action;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition-duration: 0.5s;

        &:first-child,
        &:last-child {
          position: absolute;
        }

        &:first-child {
          transform: translate(-50%, -5px);
        }

        &:last-child {
          transform: translate(-50%, 3px);
        }
      }

      .main-nav-open & {
        span {
          opacity: 0;

          &:first-child {
            opacity: 1;
            transform: translate(-50%, 0) rotate(45deg);
          }

          &:last-child {
            opacity: 1;
            transform: translate(-50%, 0) rotate(-45deg);
          }
        }
      }
    }
  }

  @include breakpoint(1024px) {
    margin-right: 0;
    transition-duration: 0.3s;
    margin-top: 10px;

    .header-icons__item--toggle-nav {
      display: none;
    }

    .search-open & {
      margin-right: 200px;
    }
  }

  @include breakpoint(1024px) {
    .search-open & {
      margin-right: 280px;
    }
  }
}

.main-menu {
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $normal;
  visibility: hidden;
  transition-duration: 0.5s;
  opacity: 0;
  transform: translate(0, -50px) scale(0.8);

  &__items {
    width: 260px;
    margin: 125px auto 50px;
  }

  &__item {
    border-top: 1px solid rgba($action, 0.25);
  }

  &__link {
    font-size: 14px;
    @include breakpoint(1060px) {
      font-size: 16px;
    }
    @include breakpoint(1400px) {
      font-size: 19px;
    }
    @include breakpoint(1400px) {
      font-size: 21px;
    }
    font-weight: 300;
    color: $white;
    display: inline-block;
    padding: 12px 0;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      bottom: 5px;
      background-color: $action;
      transform: translate(-100%, 0);
      transition-duration: 0.3s;
    }

    &.is-active,
    &:hover {
      &:before {
        transform: translate(0, 0);
      }
    }
  }

  .main-nav-open & {
    overflow-x: auto;
    visibility: visible;
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }

  @include breakpoint(1024px) {
    position: relative;
    opacity: 1;
    visibility: visible;
    float: right;
    background: 0;
    transform: none;
    padding-top: 5px;
    margin-top: 10px;

    &__items {
      margin: 0;
      width: auto;
    }

    &__item {
      display: inline-block;
      border: 0;
      padding: 0 8px;
    }

    &__link {
      padding: 0;

      &:before {
        bottom: 0px;
      }
    }
  }
  @include breakpoint(1480px) {
    &__item {
      padding: 0 15px;
    }
  }
}

.main-nav-open {
  &,
  body {
    overflow: hidden;
  }
}

.search-block {
  position: relative;
  margin-top: 30px;

  &__input[type='text'] {
    border: 1px solid rgba($action, 0.25);
    width: 100%;
    height: 45px;
    background: 0;
    padding: 0 50px;
    font-size: 18px;
    font-weight: 300;
    color: $white;
    border-radius: 99em;

    &::-webkit-input-placeholder {
      color: #717991;
    }

    &::-moz-placeholder {
      color: #717991;
    }
  }

  &__btn {
    font-size: 18px;
    color: $action;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translate(0, -50%);
  }

  @include breakpoint(1024px) {
    position: absolute;
    top: -1px;
    width: 44px;
    overflow: hidden;
    margin: 0;
    right: -50px;
    transform: translate(100%, 0);
    transition-duration: 0.3s;

    &__input[type='text'] {
      width: 0px;
      opacity: 0;
      transition-duration: 0.3s;
    }

    &__btn {
      z-index: 9;
      left: 0;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      border: 1px solid rgba($action, 0.25);
      cursor: pointer;

      &:hover {
        background-color: $action;
        color: $normal;

        .icon-search {
          width: 16px;
          height: 15px;
          background-position: -50px -50px;
        }
      }
    }

    .search-open & {
      width: 195px;

      .search-block__input[type='text'] {
        width: 100%;
        opacity: 1;
      }

      .search-block__btn {
        border-color: transparent;
      }
    }
  }

  @include breakpoint(1200px) {
    .search-open & {
      width: 275px;
    }
  }
}

.banner-intro {
  height: 100vh;
  min-height: 640px;
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba($normal, 0.9);
  }

  &__inner {
    padding: 0 20px;
    position: absolute;
    z-index: 2;
    color: $white;
    top: 50%;
    left: 50%;
    text-align: center;
    max-width: 640px;
    .goed & {
      max-width: inherit;
    }
    .not_found & {
      max-width: 840px;
    }
    width: 100%;
    transform: translate(-50%, -50%);
    .homepage & {
      margin-top: 70px;
    }
  }

  &__title {
    font-size: 40px;
    text-transform: uppercase;
    font-family: $rubik-font;
    font-weight: 500;
    margin: 10px 0 10px;
  }

  &__desc {
    font-size: 18px;
    font-weight: 300;
    font-family: $roboto-font;
    line-height: 22px;
    margin: 0 0 20px;
  }

  &__button {
    display: inline-block;
    text-align: center;
    font-size: 21px;
    font-weight: 300;
    color: $white;
    padding: 15px 25px;
    background-color: #00a9f0;
    border-radius: 5px;
    &.fb {
      background-color: #00527a;
    }

    &:hover {
      background-color: darken(#00a9f0, 5%);
    }
  }

  + .board-block {
    margin-top: -90px;
  }

  @include breakpoint(1024px) {
    &__title {
      font-size: 75px;
    }

    &__desc {
      font-size: 17px;
    }
  }
}

@keyframes MoveUpDown {
  0% {
    transform: translate(0, 30%);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 30%);
  }
}

.board-block {
  position: relative;
  z-index: 2;
  padding-bottom: 30px;
  background-color: $action;

  &__scroll {
    text-align: center;
    position: relative;
    margin-bottom: 30px;

    &-button {
      width: 50px;
      height: 50px;
      display: inline-block;
      background-color: $white;
      border-radius: 50%;
      line-height: 50px;
      font-size: 30px;
      color: $normal;
      margin-top: -35px;
      margin-bottom: 5px;

      i {
        position: relative;
        animation: MoveUpDown 1s linear infinite;
      }
    }

    &-text {
      font-size: 18px;
      font-weight: 300;
    }
  }

  &__search-outer {
    margin-bottom: 35px;
    z-index: 100;
  }

  &__search-inner {
    width: 100%;
    background-color: $action;
    padding: 10px 0;
    position: relative;

    /*&:before {
            content: "";
            position: absolute;
            left: -40px;
            right: -40px;
            top: 0;
            bottom: 0;
        }*/

    &.is-at-bottom {
      position: absolute !important;
    }
  }

  &__search {
    position: relative;
    width: 425px;
    .is-sticky & {
      width: 720px;
    }
    max-width: 100%;
    margin: 0 auto;

    &-input[type='text'] {
      width: 100%;
      height: 50px;
      border-radius: 99em;
      padding-right: 60px;
      padding-left: 30px;
      font-size: 18px;

      &::-webkit-input-placeholder {
        color: #adb5c5;
      }

      &::-moz-placeholder {
        color: #adb5c5;
      }
    }

    &-button {
      font-size: 20px;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  &__orcounter {
    display: none;
    text-align: center;

    margin: 0 auto 20px auto;
    @include breakpoint(930px) {
      width: auto;
      margin: 0 0 20px 0;
    }
    @include breakpoint(1280px) {
      display: block;
      float: right;
      position: absolute;
      top: 20px;
      right: -173px;
      width: 150px;
      height: 150px;
    }
    .is-sticky & {
      display: none !important;
    }
    z-index: 1;
    font-size: rem-calc(18px);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    padding: 23px 0;
    span {
      font-weight: bold;
      font-size: rem-calc(30px);
    }
  }

  @include breakpoint(1024px) {
    padding-bottom: 100px;
  }
}

.board-widget {
  margin-bottom: 30px;

  &__link {
    display: block;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    transition-duration: 0.5s;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__inner {
    background-color: $white;
    padding: 25px 20px;
    box-shadow: 0 0 15px 10px rgba($black, 0.1);
  }

  &__title {
    font-size: 21px;
    font-family: $rubik-font;
    font-weight: 500;
    line-height: 1.42;
    margin-bottom: 5px;
  }

  &__desc {
    font-size: 16px;
    line-height: 1.625;
    margin: 0 0 10px;
  }

  &__button {
    display: inline-block;
    font-size: 16px;
    font-weight: 300;
    color: $white;
    background-color: $normal;
    border-radius: 5px;
    padding: 8px 20px;

    &:hover {
      background-color: lighten($normal, 10%);
    }
  }

  @include breakpoint(1024px) {
    width: 450 / 1160 * 100%;
    margin-bottom: 0;

    &:nth-child(odd) {
      float: left;
      clear: left;
    }

    &:nth-child(even) {
      float: right;
      clear: right;
    }

    &--large {
      width: 600 / 1160 * 100%;

      .board-widget__inner {
        position: relative;
        z-index: 9;
        width: 450 / 600 * 100%;
      }

      &:nth-child(even) {
        .board-widget__inner {
          margin-left: 150 / 600 * 100%;
          margin-top: -80px;
        }
      }

      &:nth-child(odd) {
        margin-top: 70px;

        .board-widget__inner {
          margin-top: -80px;
        }
      }
    }
  }

  @include breakpoint(1200px) {
    &__inner {
      padding: 50px 40px 60px;
    }

    &__title {
      font-size: 24px;
      margin: 0 0 15px;
    }

    &__desc {
      font-size: 18px;
      margin: 0 0 20px;
    }

    &__button {
      font-size: 18px;
    }
  }
}

.email-block {
  overflow: hidden;

  &__input[type='email'] {
    width: 190 / 300 * 100%;
    float: left;
    border: 1px solid $normal;
    height: 40px;
    padding: 0 10px;
    font-size: 16px;
    border-radius: 5px;
  }

  &__button {
    @extend .board-widget__button;
    width: 95 / 300 * 100%;
    float: right;
    height: 40px;
  }
}

.usp-block {
  padding: 60px 0;
  background: $white;

  &__title {
    font-family: $rubik-font;
    font-size: 30px;
    font-weight: 300;
    line-height: 1.3333;
    text-align: center;
    max-width: 1050px;
    margin: 0 0 50px;
  }

  &__item {
    padding-left: 35px;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e2e4e7;

    &-title {
      font-family: $rubik-font;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 15px;

      &:before {
        content: '';
        background-image: url(../images/icon-sprites.png);
        background-repeat: no-repeat;
        display: inline-block;
        width: 19px;
        height: 18px;
        background-position: 0 -200px;
        position: absolute;
        left: 0;
        display: inline-block;
      }
    }
    .cke_editable {
      .usp-block__item-title {
        &:before {
          left: -30px;
        }
      }
    }

    &-desc {
      font-size: 18px;
      font-weight: 300;
      line-height: 1.444;
    }
  }

  .columns:last-child .usp-block__item {
    border-bottom: 0;
  }

  &__buttons {
    text-align: center;
  }

  &__button {
    @extend .board-widget__button;
    min-width: 250px;
    font-size: 21px;
    padding: 15px 20px;
  }

  @include breakpoint(1024px) {
    padding: 80px 0;

    &__title {
      font-size: 40px;
    }

    &__item {
      margin: 0;
      border-bottom: 0;
      padding: 30px 20px 40px 35px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        background-color: #e2e4e7;
        left: -35px;
      }
    }

    .columns:first-child {
      .usp-block__item:before {
        display: none;
      }
    }

    &__buttons {
      margin-top: 50px;
    }
  }
}

.quote-block {
  padding: 60px 0;
  text-align: center;
  font-family: $rubik-font;
  font-weight: 400;
  background-color: #ebecef;
  > .row {
    max-width: 70rem;
  }
  position: relative;
  button {
    position: absolute;
    top: 45px;
    &.slick-prev {
      left: -13px;
    }

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 65px 25px;
      border-color: transparent #d3d8e4 transparent transparent;
      position: absolute;
      right: 0;
      top: 0;
      display: none;
      @include breakpoint(768px) {
        display: block;
      }
    }
    &.slick-next {
      right: -60px;
      &:after {
        border-color: transparent transparent transparent #d3d8e4;
      }
    }
  }

  &__quote {
    font-size: 30px;
    font-weight: 300;
    line-height: 1.3333;
  }

  &__name {
    margin-top: 20px;
    font-size: 18px;
  }

  @include breakpoint(1024px) {
    padding: 80px 0;

    &__quote {
      font-size: 40px;
    }

    &__name {
      font-size: 21px;
      margin-top: 30px;
    }
  }
}

.footer-page {
  padding: 45px 0;
  background-color: #545f77;
  text-align: center;
  color: $white;

  &__copy {
    display: block;
    font-size: 14px;
    font-weight: 300;
    margin: 0 0 25px;

    a {
      color: $white;
    }
  }

  @include breakpoint(1024px) {
    padding: 30px 0;

    &__copy {
      font-size: 16px;
      float: left;
      margin: 10px 0 0;
    }

    .social-block {
      float: right;
    }
  }
}

.social-block {
  &__item {
    display: inline-block;
    vertical-align: middle;
    width: 45px;
    height: 45px;
    color: #545f77;
    background-color: #7f8799;
    line-height: 45px;
    text-align: center;
    font-size: 18px;
    border-radius: 50%;

    &:hover {
      color: $white;
    }
  }
}
