// font variables
$font:          'Source Sans Pro', sans-serif;
$rubik-font:    'Rubik', sans-serif;
$roboto-font:   'Roboto', sans-serif;

// color variables
$normal:		#3c475e;
$action:		#ffea00;
$alert:			#EC5840;
$success:       #50b435;
$grey:			#f1f1f1;
$dark_grey:		#b9b9b9;
$light_normal: 	#aeb9d4;
$border_bolor:	#e5edf1;
$lighter_normal:#545f77;






