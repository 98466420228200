/*Home page*/
.homepage {
  .banner-head {
    width: 100%;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(84, 95, 119, 0.25);
    }
    min-height: 320px;
    @include breakpoint(768px) {
      min-height: 480px;
    }
    .center-valign {
      text-align: center;
      position: relative;
      z-index: 222;
      color: #fff;
      width: 100%;
      height: 320px;
      @include breakpoint(768px) {
        height: 480px;
      }
    }
    .content {
      width: 100%;
      padding: rem-calc(0 20px);
      margin: 0 auto;
      @include breakpoint(768px) {
        width: 65%;
        margin: 0 auto;
      }
    }
    h2 {
      font-weight: normal;
      margin-bottom: rem-calc(30);
      font-size: rem-calc(30);
      line-height: rem-calc(35);
      @include breakpoint(768px) {
        margin-bottom: rem-calc(30);
        font-size: rem-calc(40);
        line-height: rem-calc(52);
      }
      @include breakpoint(1024px) {
        font-size: rem-calc(48);
        line-height: rem-calc(60);
        margin-bottom: rem-calc(50);
      }
    }
  }
  .homepage_cat {
    padding: 24px 0 40px;
    .cat_button {
      padding-top: 10px;
    }
  }
  .search-form {
    border: 7px solid rgba(255, 255, 255, 0.25);
    border-radius: 20px;
    max-width: 570px;
    @include breakpoint(1024px) {
      border-width: 10px;
    }
    input[type='text'] {
      height: 50px;
      width: 67%;
      @include breakpoint(768px) {
        width: 79%;
      }
      @include breakpoint(1024px) {
        height: 60px;
      }
    }
    .button {
      height: 50px;
      width: 33.42%;
      @include breakpoint(768px) {
        width: 21.42%;
      }
      @include breakpoint(1024px) {
        height: 60px;
      }
    }
  }

  #middle {
    margin-top: -20px;
    padding-top: 0;
    padding-bottom: 0px;
  }
  .feature-col {
    .box-content {
      position: relative;
      padding-bottom: 77px;
      .button,
      .form-buttons input[type='submit'] {
        position: absolute;
        bottom: 15px;
        right: 20px;
        @include breakpoint(1024px) {
          bottom: 35px;
          right: 40px;
        }
      }
      .icon {
        font-size: 37px;
        color: #aeb9d4;
        margin-top: -5px;
        margin-left: -9px;
        text-align: center;
      }
      h2 {
        font-size: 1.7rem;
        line-height: 2rem;
      }
    }
  }
}

/* overzichts page */
.overzichts {
  @include between-breakpoints(768px, 980px) {
    .medium-up-2 > .column {
      width: 80%;
      float: none;
      margin: 0 auto;
    }
  }
  h1 {
    font-size: rem-calc(36);
    margin: rem-calc(0 0 40px);
  }
  .item-article {
    overflow: hidden;
    margin-bottom: 40px;
    .thumb {
      overflow: hidden;
      display: block;
      @include breakpoint(480px) {
        float: left;
        width: 47%;
      }
      @include between-breakpoints(768px, 980px) {
        width: 35%;
      }
    }
    img {
      width: 100%;
      display: block;
      transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
    }
    &:hover {
      img {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
      }
    }
    .over {
      overflow: hidden;
      padding: 20px;
      @include breakpoint(1025px) {
        padding: 35px 40px 20px;
      }
    }
    h2.title {
      font-size: rem-calc(24);
      margin-bottom: 5px;
      a {
        color: $normal;
        &:hover {
          color: lighten($normal, 10%);
        }
      }
    }
    .text {
      line-height: 20px;
      margin-bottom: 25px;
      font-size: 16px;
      max-height: 80px;
      overflow: hidden;
    }
    .button {
      float: right;
    }
  }
}

/* artikelen page */
.artikelen {
  h1 {
    font-size: rem-calc(36);
    margin: rem-calc(0 0 40px);
  }
  .box-content {
    .medium-6 {
      @include between-breakpoints(768px, 1023px) {
        width: 100%;
      }
    }
  }
  .accordion-item {
    margin-bottom: 40px;
    border: #f1f1f1 1px solid;
    border-width: 1px 0;
    @include between-breakpoints(100px, 767px) {
      border: none;
      margin-bottom: 30px;
    }
    &.is-active {
      .accordion-title {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
    .accordion-title {
      font-weight: bold;
      color: $normal;
      font-size: rem-calc(18);
      position: relative;
      overflow: hidden;
      line-height: 20px;
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 6px 0 6px;
        border-color: #aeb9d4 transparent transparent transparent;
        position: absolute;
        right: 15px;
        top: 27px;
        display: none;
        @include breakpoint(768px) {
          display: block;
        }
      }
      span {
        width: 60px;
        height: 60px;
        line-height: 60px;
        display: inline-block;
        background: #e0cd00;
        text-align: center;
        margin-right: 20px;
        float: left;
        @include between-breakpoints(100px, 767px) {
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
      }
      &:hover,
      &:focus {
        background: none;
      }
      div {
        float: left;
        max-width: 190px;
        padding: 0px;
        @include breakpoint(371px) {
          max-width: 245px;
          padding: 0px;
        }
        @include breakpoint(450px) {
          max-width: 300px;
          padding: 10px;
        }
        @include breakpoint(1150px) {
          max-width: 410px;
          padding: 10px;
        }
      }
    }
    .accordion-content {
      background: #f9f5cc;
      padding: 20px;
      font-size: rem-calc(18);
      line-height: rem-calc(25);
      p {
        margin-bottom: 15px;
      }
      @include breakpoint(1024px) {
        padding: 35px 40px 25px;
      }
      strong,
      a {
        color: $normal;
        &:hover {
          color: #e0cd00;
        }
      }
    }
  }
}

/* Single */
.single {
  .title-sidebar {
    font-size: rem-calc(24);
    margin-bottom: 30px;
  }
  .content-col {
    h1 {
      font-size: rem-calc(30);
      line-height: rem-calc(36);
      margin: 0 0 30px;
      @include breakpoint(768px) {
        font-size: rem-calc(36);
        line-height: rem-calc(40);
      }
    }
    ol {
      margin-left: 15px;
      font-weight: bold;
      font-size: 18px;
      li {
        font-weight: normal;
        font-size: rem-calc(18);
        line-height: rem-calc(30);
        padding-left: 10px;
        margin-bottom: 30px;
      }
      ul {
        padding: 0;
        margin: 0 0 0 30px;
      }
    }
  }

  .accordion {
    margin-bottom: 40px;
  }
  .accordion-item {
    margin-bottom: 20px;
    border: #f1f1f1 1px solid;
    border-width: 1px 0;
    // @include between-breakpoints(100px, 767px) {
    // 	border: none;
    // 	margin-bottom: 30px;
    // }

    .accordion-title {
      font-weight: bold;
      color: $normal;
      font-size: rem-calc(18);
      line-height: rem-calc(27);
      position: relative;
      overflow: hidden;
      padding: 12px 0;
      span {
        width: 40px;
        text-align: center;
        @include breakpoint(780px) {
          float: left;
        }
      }

      strong {
        overflow: hidden;
        display: block;
        font-size: rem-calc(15);
        line-height: rem-calc(24);
        @include breakpoint(780px) {
          font-size: rem-calc(18);
          line-height: rem-calc(27);
        }
      }
      &:hover,
      &:focus {
        background: none;
      }
    }
    .accordion-content {
      padding: 15px 0 20px 20px;
      font-size: rem-calc(18);
      line-height: rem-calc(25);
      border-top: #f1f1f1 1px solid;
      padding-top: 15px;
      p {
        margin-bottom: 15px;
      }
      @include breakpoint(1024px) {
        padding: 15px 0 15px 40px;
      }
      strong,
      a {
        color: $normal;
        font-size: rem-calc(15);
        line-height: rem-calc(24);
        @include breakpoint(780px) {
          font-size: rem-calc(18);
          line-height: rem-calc(27);
        }
        &:hover {
          color: #e0cd00;
        }
      }
    }
    &.is-active {
      .accordion-content {
        .singleArticle {
          &.is-active {
            color: #e0cd00;
          }
        }
      }
    }
  }

  #thanks_to {
    border: 1px solid #d8dadf;
    padding: 10px 15px;
    float: right;
    margin: 0 0 20px 20px;
    img {
      border: 0;
      margin-top: 8px;
    }
  }
}

/* Normal page */
.normal-page {
  .whitebox {
    margin-bottom: 30px;
  }
  .gowidget,
  .widget {
    overflow: hidden;
    padding: 20px;
    font-size: rem-calc(20);
    line-height: rem-calc(26);
    margin-bottom: rem-calc(30);
    font-weight: 700;
    @include breakpoint(1024px) {
      padding: 25px 30px;
      font-size: rem-calc(24);
      line-height: rem-calc(30);
    }
    p {
      margin-bottom: rem-calc(30);
    }

    fieldset {
      border: 0;
    }
    input[type='password'],
    input[type='text'],
    textarea {
      border-radius: 5px;
      box-shadow: inset 0 0 5px 0 rgba(60, 71, 94, 0.25);
      width: 100%;
      min-height: 50px;
      margin-bottom: 20px;
    }
    .submit a {
      display: inline-block;
      font: italic 700 rem-calc(16) $font;
      color: #fff;
      padding: 9px 20px 10px;
      border: none;
      background-color: $normal;
      border-radius: 30px;
      float: right;
      margin-top: -10px;
      &:hover {
        background: darken($normal, 5%);
      }
    }
    .feedback {
      font-size: 1rem;
      &.error {
        color: #ff0000;
      }
      &.success {
        font-size: inherit;
      }
    }
    .firstname,
    .infix,
    .lastname {
      display: none;
    }
  }
  .thumb-cover {
    margin-bottom: 30px;
    min-height: 1px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .sub-nav-wrap {
    padding: 0 20px;
    margin-bottom: 30px;
    .head-title {
      font-size: rem-calc(24);
      border-bottom: $action 2px solid;
      padding-bottom: 10px;
      margin-bottom: 25px;
    }
    ul {
      list-style: none;
      li {
        font-size: 18px;
        margin-bottom: 15px;
        a {
          color: $normal;
          &:hover {
            color: #e0cd00;
          }
        }
        &.active {
          font-weight: bold;
          a {
            color: #e0cd00;
          }
        }
      }
    }
  }
  .content-col {
    padding: 0 20px;
    @include breakpoint(768px) {
      padding-left: 0;
    }
    h1 {
      font-size: rem-calc(36);
      line-height: rem-calc(40);
      margin: 0 0 30px;
    }
  }

  .medium-12 {
    #main_content {
      .whitebox {
        .row {
          .medium-12 {
            padding-left: 60px;
          }
        }
      }
    }
  }
}

.zoekresultaten {
  h1 {
    font-size: rem-calc(36);
    margin: rem-calc(0 0 15px);
    span {
      color: #e0cd00;
    }
  }
  .whitebox {
    margin-bottom: 30px;
  }
  .widget {
    overflow: hidden;
    padding: 20px;
    @include breakpoint(1024px) {
      padding: 35px 40px;
    }

    p {
      margin-bottom: rem-calc(30);
    }
    .form-form {
      .form-buttons input[type='submit'] {
        float: right;
        margin-top: -10px;
      }
    }
  }
  .accordion-item {
    margin-bottom: 40px;
    border: #f1f1f1 1px solid;
    border-width: 1px 0;
    @include between-breakpoints(100px, 767px) {
      border: none;
      margin-bottom: 30px;
    }
    &.is-active {
      .accordion-title {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
    .article_text {
      padding: 10px 0;
      border-top: #f1f1f1 1px solid;
    }
    .accordion-title {
      font-weight: bold;
      color: $normal;
      font-size: rem-calc(16);
      position: relative;
      overflow: hidden;
      line-height: 20px;

      > span {
        padding: 10px 15px;
        display: inline-block;
        background: #e0cd00;
        text-align: center;
        margin-right: 20px;
        line-height: 16px;
        font-size: 14px;
        font-weight: normal;
        vertical-align: middle;
        strong {
          font-size: 18px;
        }
        @include between-breakpoints(100px, 480px) {
          float: left;
        }
      }
      &:hover,
      &:focus {
        background: none;
      }
    }
    .accordion-cont {
      span {
        font-weight: 700;
      }
      .list-item-sidebar {
        overflow: hidden;
        padding-top: 0;
        li {
          overflow: hidden;
          margin-bottom: 0;
          border-top: #fff 1px solid;
          border-bottom: #f9f5cc 18px solid;
          min-height: 175px;
          max-height: 175px;
          .title {
            a {
              font-size: rem-calc(14);
            }
          }
          @include breakpoint(481px) {
            float: left;
            width: 50%;
            border-left: #fff 1px solid;
            .title {
              a {
                font-size: rem-calc(16);
              }
            }
          }
          @include breakpoint(780px) {
            float: left;
            width: 33.33%;
            border-left: #fff 1px solid;
            .title {
              a {
                font-size: rem-calc(18);
              }
            }
          }
        }
        .text {
          font-weight: normal;
          font-size: 14px;
          line-height: 14px;
        }
        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .page-list {
    .title-box {
      font-size: rem-calc(24);
      margin-bottom: 30px;
    }
  }
  .list-item-page {
    list-style: none;
    margin-bottom: -30px;
    li {
      overflow: hidden;
      margin-bottom: 40px;
      img {
        width: 100%;
        margin-bottom: 15px;
        @include breakpoint(481px) {
          float: left;
          max-width: 140px;
          margin-bottom: 0;
        }
      }
      .over {
        overflow: hidden;
        @include breakpoint(481px) {
          padding-left: 20px;
        }
      }
      .title {
        font-size: rem-calc(24);
        a {
          color: $normal;
          &:hover {
            color: #e0cd00;
          }
          i {
            color: #e0cd00;
          }
        }
      }
      .path {
        font-size: 14px;
        color: #aeb9d4;
        margin-bottom: 10px;
      }
      .text {
        font-size: 16px;
        line-height: 20px;
      }
      .fa {
        width: 30px;
        text-align: center;
      }
    }
  }

  .highlight {
    background-color: #ffea00;
  }
  .faq {
    padding-top: 20px;
    h3 {
      border-bottom: 1px solid #f1f1f1;
      margin: 0 0 1px 0;
      padding: 0 0 5px 0;
    }
    .qanda-box-link {
      .inner-body {
        min-height: 260px;
      }
    }
    .qanda-box-register {
      p {
        font-size: 16px;
      }
      .button {
        font-size: 17px;
        padding: 10px 36px 11px;
      }
    }
  }

  .title-box {
    font-size: 38px;
    font-weight: normal;
    margin-bottom: 15px;
  }
  .box-content {
    &.blue {
      margin-top: -40px;
      background-color: $normal;
      color: #fff;
      form {
        margin-top: 15px;
      }
    }
  }
}

/* Vraag page */
.vraag {
  h1 {
    font-size: rem-calc(36);
    font-weight: bold;
    margin: 0;
    padding: 0;
    line-height: 1;
    margin-right: 13px;
    margin-bottom: 20px;
    @include breakpoint(768px) {
      float: left;
      margin-bottom: 50px;
    }
    .number {
      color: #aeb9d4;
    }
  }
  .timebar {
    overflow: hidden;
    background: $grey;
    height: 30px;
    margin-top: 4px;
    position: relative;
    margin-bottom: 50px;
    transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    &.over {
      background-color: $alert;
      color: #fff;
    }
    .countdown {
      font-size: rem-calc(18);
      font-weight: bold;
      padding: 0 10px;
      height: 30px;
      line-height: 30px;
      position: relative;
      z-index: 222;
    }
    .bar {
      background-color: $action;
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
    }
  }
  .desc {
    clear: both;
    font-size: rem-calc(18);
    line-height: rem-calc(30);
    margin-bottom: 40px;
    @include breakpoint(1024px) {
      margin-bottom: 70px;
    }
  }
  .answer-list {
    list-style: none;
    li {
      border: $normal 1px solid;
      color: $normal;
      font-size: rem-calc(18);
      padding: 8px 20px;
      margin-bottom: 20px;
      transition: all 0.15s ease-in;
      -moz-transition: all 0.15s ease-in;
      -webkit-transition: all 0.15s ease-in;
      cursor: pointer;
      &:hover {
        background-color: $normal;
        color: #fff;
      }
      &:active {
        background-color: lighten($normal, 10%);
      }
      strong {
        font-size: rem-calc(36);
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px;
        margin-right: 13px;
      }
    }
  }

  .questions .question_container ~ .question_container {
    display: none;
  }
}

.list-colleega {
  list-style: none;
  margin-bottom: 30px;
  li {
    overflow: hidden;
    border-bottom: $border_bolor 1px solid;
    padding: 7px 15px 7px 10px;
    font-size: 16px;
    &:first-child {
      border-top: $border_bolor 1px solid;
    }
    span {
      float: left;
      color: $light_normal;
      strong {
        color: $normal;
        margin-right: 5px;
        @include between-breakpoints(100px, 767px) {
          display: block;
        }
      }
    }
    a {
      float: right;
      color: $normal;
    }
  }
}

/* Goed page */
.goed {
  .close {
    cursor: pointer;
  }
  #col_message {
    &.error {
      color: #f00;
    }
  }
  .banner-head {
    width: 100%;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#545f77+0,ffffff+100&0.65+0,0+100 */
      background: -moz-linear-gradient(
        top,
        rgba(84, 95, 119, 0.65) 0%,
        rgba(255, 255, 255, 0) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(84, 95, 119, 0.65) 0%,
        rgba(255, 255, 255, 0) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(84, 95, 119, 0.65) 0%,
        rgba(255, 255, 255, 0) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6545f77', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
      // background-color: rgba(84, 95, 119, 0.5);
      background-color: rgba(84, 95, 119, 0.15);
    }
    .center-valign {
      text-align: center;
      color: #fff;
      height: 320px;
      position: relative;
      z-index: 222;
      @include breakpoint(768px) {
        height: 480px;
      }
    }
    h1,
    h2 {
      font-size: rem-calc(35);
      line-height: rem-calc(45);
      text-shadow: 0 0 25px rgba(60, 71, 94, 0.5);
      // margin: 0 0 60px;
      @include breakpoint(768px) {
        font-size: rem-calc(48);
        line-height: rem-calc(60);
        margin: 0 0 60px;
      }
      span {
        color: $action;
        &.smaller {
          font-size: rem-calc(29);
          color: #fff;
        }
      }
    }
    .button-group {
      font-size: 0;
      a {
        border-radius: 30px;
        color: #fff;
        background-color: #009fe3;
        font-size: rem-calc(18);
        font-weight: bold;
        font-style: italic;
        padding: 0 33px;
        line-height: 38px;
        display: inline-block;
        height: 40px;
        margin: 5px;
        @include breakpoint(768px) {
          margin: 10px;
        }
        &:hover {
          background-color: darken(#009fe3, 10%);
        }
        &.linkedin {
          background-color: #005279;
          &:hover {
            background-color: darken(#005279, 10%);
          }
        }
      }
    }
  }

  .wrap-content {
    margin-top: -50px;
    @include breakpoint(768px) {
      margin-top: -110px;
    }
    h2 {
      font-size: rem-calc(36);
      margin-bottom: 25px;
    }
    .form-buttons {
      text-align: right;
      input {
        background: none;
        font-weight: normal;
        border: $light_normal 1px solid;
        color: $normal;
        &:hover {
          background-color: $normal;
          border-color: $normal;
          color: #fff;
        }
      }
    }
  }

  .form-form {
    label {
      padding-bottom: 5px;
      font-style: italic;
      font-size: 16px;
    }
    .field {
      margin-bottom: 0;
    }
    .form-controls {
      width: 100%;
    }
    input[type='text'] {
      min-height: 50px !important;
      width: 100%;
      &.error {
        background-color: lighten($alert, 35%) !important;
      }
    }
    .form-label {
      width: 100%;
    }
  }
  #forms_container {
    #sendForm {
      cursor: pointer;
    }
  }
  .call-action {
    text-align: right;
    border-top: $border_bolor 1px solid;
    margin-top: 20px;
    padding-top: 40px;
    .button {
      background: $action;
      color: $normal;
      &:hover {
        background: darken($action, 5%);
      }
    }
  }
  .box-subscribe {
    background: $normal;
    color: #fff;
    h3 {
      font-size: rem-calc(18);
      line-height: rem-calc(30);
      margin-bottom: 35px;
    }
    .form-form {
      input[type='text'] {
        height: 40px;
        min-height: 40px;
        border: $light_normal 1px solid;
      }
      .form-buttons {
        text-align: right;
      }
      input[type='submit'] {
        background: $action;
        color: $normal;
        &:hover {
          background: darken($action, 5%);
        }
      }
    }
  }
  .box-result {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
    h2 {
      border-bottom: $border_bolor 1px solid;
      padding-bottom: 25px;
    }
    h3,
    h4,
    h5,
    h6 {
      font-weight: normal;
      span {
        color: darken($action, 5%);
        margin-left: 5px;
      }
    }
    h3 {
      font-weight: bold;
      font-size: rem-calc(30);
      @include breakpoint(768px) {
        font-size: rem-calc(48);
      }
    }
    h4 {
      font-size: rem-calc(30);
      @include breakpoint(768px) {
        font-size: rem-calc(45);
      }
    }
    h5 {
      font-size: rem-calc(27);
      @include breakpoint(768px) {
        font-size: rem-calc(42);
      }
    }
    h6 {
      font-size: rem-calc(24);
      @include breakpoint(768px) {
        font-size: rem-calc(39);
      }
    }
    .blur-text {
      color: $dark_grey;
      font-size: rem-calc(24);
      padding-top: 10px;
    }
  }

  .answer_block {
    margin-bottom: 15px;
  }

  .answer-list {
    list-style: none;
    > li {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .answer {
      /*background: #e5ffe5;*/
      font-size: rem-calc(18);
      font-weight: bold;
      padding: 0 20px 12px 20px;
      margin-bottom: 5px;
      &.wrong {
        /*background: #ffe5e5;*/
      }
    }
    .answer_title {
      border-left: $normal 1px solid;
      border-top: $normal 1px solid;
      border-right: $normal 1px solid;
      width: 100%;
      padding: 2px 7px;
      font-size: rem_calc(13);
      background-color: $normal;
      color: #fff;
      &.wrong {
        border-left: lighten($alert, 10%) 1px solid;
        border-right: lighten($alert, 10%) 1px solid;
        border-top: lighten($alert, 10%) 1px solid;
        background-color: lighten($alert, 10%);
      }
      &.success {
        border-left: $success 1px solid;
        border-right: $success 1px solid;
        border-top: $success 1px solid;
        background-color: $success;
      }
    }
    .expand {
      border: $normal 1px solid;
      color: $normal;
      font-size: rem-calc(18);
      padding: 8px 20px;
      margin: 0 0 5px 0;

      strong {
        font-size: rem-calc(36);
        font-weight: bold;
        position: relative;
        line-height: 1;
        margin-right: 20px;
        float: left;
      }
      .over {
        overflow: hidden;
        font-size: rem-calc(18);
        line-height: 24px;
        font-style: italic;
        padding: 8px 0;
        p:first-child {
          margin-bottom: 20px;
          font-style: normal;
        }
      }
      &.wrong {
        border: lighten($alert, 10%) 1px solid;
        color: lighten($alert, 10%);
      }
    }
  }
}

.profiel {
  .banner-head {
    width: 100%;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#545f77+0,ffffff+100&0.65+0,0+100 */
      background: -moz-linear-gradient(
        top,
        rgba(84, 95, 119, 0.65) 0%,
        rgba(255, 255, 255, 0) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(84, 95, 119, 0.65) 0%,
        rgba(255, 255, 255, 0) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(84, 95, 119, 0.65) 0%,
        rgba(255, 255, 255, 0) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6545f77', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
      // background-color: rgba(84, 95, 119, 0.5);
      background-color: rgba(84, 95, 119, 0.15);
    }
    .center-valign {
      text-align: center;
      color: #fff;
      height: 320px;
      position: relative;
      z-index: 8;
      @include breakpoint(768px) {
        height: 480px;
      }
    }
    h1,
    h2 {
      font-size: rem-calc(35);
      line-height: rem-calc(45);
      text-shadow: 0 0 25px rgba(60, 71, 94, 0.5);
      // margin: 0 0 60px;
      @include breakpoint(768px) {
        font-size: rem-calc(48);
        line-height: rem-calc(60);
        margin: 0 0 60px;
      }
    }
  }
  .wrap-content {
    margin-top: -90px;
    @include breakpoint(768px) {
      margin-top: -150px;
    }
  }
  h2 {
    margin-bottom: 35px;
    margin-top: 5px;
  }
  .button.float-right {
    @include between-breakpoints(100px, 480px) {
      float: left !important;
      margin-bottom: 30px;
      margin-top: -20px;
    }
  }
  .tabs {
    overflow: hidden;
    list-style: none;
    li {
      line-height: 50px;
      height: 50px;
      padding: 0 10px;
      background: #fff;
      margin-left: 5px;
      font-size: 15px;
      font-weight: bold;
      float: left;
      color: $light_normal;
      text-transform: uppercase;
      border-radius: 5px 5px 0 0;
      position: relative;
      z-index: 15;
      @include breakpoint(480px) {
        padding: 0 25px;
        margin-left: 10px;
        font-size: rem-calc(18);
      }
      &.current {
        a {
          color: $normal;
        }
        position: relative;
        z-index: 2222;
      }
      a {
        color: $light_normal;
      }
    }
  }
  .tab-wrap {
    position: relative;
    z-index: 22;
    .box-content {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  .edit-profile {
    clear: both;
    padding-left: 33%;
    position: relative;
    border-top: $border_bolor 1px solid;

    padding-top: 30px;
    .picture-profile {
      position: absolute;
      left: 0;
      top: 42px;
      width: 26%;
      text-align: center;
      @include breakpoint(480px) {
        left: 20px;
        width: 24%;
      }
      img {
        display: block;
        border-radius: 50%;
        margin: 0 0 20px 0;
      }
    }
    label {
      font-style: italic;
      font-size: rem-calc(18);
      padding-bottom: 5px;
    }
    input[type='text'] {
      margin-bottom: 0;
    }
    .form-field {
      padding: 0 5px 0 0;
    }
    &.add {
      padding-left: 7%;
    }
  }
  .grey_line {
    border-top: $border_bolor 1px solid;
    width: 100%;
  }
  .list-user {
    position: relative;

    list-style: none;
    padding-bottom: 10px;
    li {
      overflow: hidden;
      margin-top: 40px;

      img {
        float: left;
        width: 15%;
        overflow: hidden;
        margin: 0 20px 0 0px;
        border-radius: 50%;
        min-width: 80px;
        @include breakpoint(480px) {
          margin: 0 40px 0 20px;
        }
      }
      .over {
        overflow: hidden;
        font-size: rem-calc(18);
        padding-top: 12px;
        @include breakpoint(480px) {
          padding-top: 22px;
        }
      }
      .name {
        font-weight: bold;
      }
      a {
        color: $normal;
      }
      .edit_block {
        float: right;
        padding: 15px 0 0 0;
        .button {
          color: #fff;
          &.small {
            padding: 9px 12px 10px;
            margin: 0 5px 0 0;
          }
          &.action {
            background: $action;
            color: $normal;
            float: right;
          }
          &.red {
            background: $alert;
            color: $white;
            float: right;
            &:hover {
              background: darken($alert, 5%);
            }
          }
        }
      }
    }
  }
  .list-content {
    li {
      border-top: $border_bolor 1px solid;
      padding-top: 40px;
      &:first-child {
        border-top: none;
        padding-top: 0;
      }
      .over {
        padding-top: 0;
      }
      .date {
        color: $light_normal;
      }
      textarea {
        min-height: 140px;
      }
      .button {
        background: $action;
        color: $normal;
        float: right;
        &:hover {
          background: darken($action, 5%);
        }
      }
    }
  }
  .login_container {
    border: 7px solid rgba(255, 255, 255, 0.25);
    border-radius: 20px;
    color: $normal;
    > div {
      background-color: #fff;
      border-radius: 14px;
      padding: 10px;
    }
    .login-form {
      width: 60%;
      text-align: left;
      margin: 0 auto;
      a {
        color: $normal;
      }
    }
  }
  .message {
    font-size: 1rem;
    &.error {
      color: #ff0000;
    }
    &.success {
      font-size: inherit;
    }
  }
  #progress {
    height: 20px;
    width: 100%;
    display: none;
    border: 1px solid #ccc;
    .bar {
      background-color: $success;
      height: 20px;
    }
  }
  #previewImage {
    height: 100px;
    padding: 10px 10px 10px 0;
  }
  .button {
    cursor: pointer;
    &.hide {
      display: none;
    }
  }

  .table {
    border-spacing: 2px;
    td {
      padding: 3px 6px;
    }
    thead {
      tr > td {
        background-color: $normal;
        color: #ffffff;
      }
    }
  }
}

/* Question page */
.question {
  .box-content {
    position: relative;
  }

  h2 {
    font-size: rem-calc(30);
    font-weight: bold;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    margin-right: 13px;
    color: #3c475e;
    margin-bottom: 60px;
    font-family: Rubik;
  }

  .question-step {
    font-family: Rubik;
    font-size: rem-calc(21);
    color: #3c475e;
    font-weight: 500;
    margin-bottom: 20px;
    span.nums {
      color: #00a9f0;
    }
  }

  .timebar {
    overflow: hidden;
    background: $grey;
    height: 30px;
    margin-top: 4px;
    position: relative;
    margin-bottom: 50px;
    transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
    &.over {
      background-color: $alert;
      color: #fff;
    }
    .countdown {
      font-size: rem-calc(18);
      font-weight: bold;
      padding: 0 10px;
      height: 30px;
      line-height: 30px;
      position: relative;
      z-index: 222;
    }
    .bar {
      background-color: #f8ce00;
      position: absolute;
      top: 0;
      left: 0;
      height: 30px;
      transition: all 0.3s ease-in;
      -moz-transition: all 0.3s ease-in;
      -webkit-transition: all 0.3s ease-in;
    }
  }
  .desc {
    clear: both;
    font-size: rem-calc(18);
    line-height: rem-calc(30);
    margin-bottom: 40px;
    @include breakpoint(1024px) {
      margin-bottom: 70px;
    }
  }

  .answer-list {
    list-style: none;
    li {
      border: $normal 1px solid;
      color: $normal;
      font-size: rem-calc(18);
      padding: 8px 20px;
      margin-bottom: 20px;
      transition: all 0.15s ease-in;
      -moz-transition: all 0.15s ease-in;
      -webkit-transition: all 0.15s ease-in;
      cursor: pointer;
      &:hover {
        background-color: #0096d6;
        color: #fff;
        border-color: #0096d6;
      }
      &:active {
        background-color: lighten(#0188c1, 10%);
      }
      strong {
        font-size: rem-calc(36);
        font-weight: bold;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px;
        margin-right: 13px;
      }
    }
  }

  .questions .question_container ~ .question_container {
    display: none;
  }

  #wait {
    text-align: center;
    h2 {
      margin-bottom: 0;
    }
  }
  #register_feedback {
    &.error {
      color: #f00;
    }
  }
}

/* register page */

/* Keyframes for the fade-in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadein {
  opacity: 0;
  opacity: 1 \9; /*just in case ie*/
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadein.one {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.fadein.two {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.register-popup {
  position: absolute;
  background: rgba(255, 255, 255, 0.97);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .register-main {
    min-width: 250px;
    max-width: 380px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
      margin-bottom: 10px;
      font-size: rem-calc(30);
      font-family: Rubik;
    }

    .register-decs {
      font-size: rem-calc(18);
      margin-bottom: 20px;
    }

    .form-form {
      input[type='text'],
      select {
        font-family: Roboto;
        border-radius: 0;
        box-shadow: 0 0 0;
        border: 1px solid #e5e5e4;
        font-size: rem-calc(16);
        padding: 0 10px;
        margin-bottom: 0;
      }

      input[type='text']::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #9f9f9f;
      }

      input[type='text']::-moz-placeholder {
        /* Firefox 19+ */
        color: #9f9f9f;
      }

      input[type='text']:-ms-input-placeholder {
        /* IE 10+ */
        color: #9f9f9f;
      }

      input[type='text']:-moz-placeholder {
        /* Firefox 18- */
        color: #9f9f9f;
      }

      select {
        color: #9f9f9f;
        padding: 10px;
      }

      .form-controls {
        display: flex;

        input,
        select {
          border-top: 0;
        }

        input {
          &:nth-child(2) {
            border-left: 0;
          }
        }

        &:first-child {
          input,
          select {
            border-top: 1px solid #e5e5e4;
          }
        }

        &.checkbox {
          margin-top: 20px;

          input[type='checkbox'] {
            display: none;
          }

          input[type='checkbox'] + label {
            color: #3c475e;
            font-size: rem-calc(18);

            span {
              display: inline-block;
              width: 25px;
              height: 25px;
              margin: -2px 10px 0 0;
              vertical-align: middle;
              border: 1px solid #e5e5e4;
              cursor: pointer;
              position: relative;
            }
          }

          input[type='checkbox']:checked + label {
            span {
              &:before {
                content: ' ';
                background-image: url(../images/icon-sprites.png);
                position: absolute;
                display: inline-block;
                background-position: 0 -198px;
                width: 19px;
                height: 25px;
                left: 2px;
              }
            }
          }
        }
      }

      .form-button {
        margin-top: 35px;

        .button {
          width: 100%;
          cursor: pointer;
          padding: 12px 0;
          text-align: center;
          background: #00a9f0;
          border-radius: 6px;
          font-size: 18px;
          font-weight: 300;
          font-style: normal;
          &.grey {
            background: #e7e9e6;
            color: #000;
          }
        }
      }
    }
  }
  &.invite {
    .register-main {
      .form-form {
        input,
        select {
          border-top: 1px solid #e5e5e4;
          &.error {
            color: #f00;
            border: 1px solid #f00;
          }
        }
      }
      #add_colleague {
        float: left;
        max-width: 160px;
        max-height: 45px;
        &:before {
          content: '\f055';
          display: inline-block;
          width: 17px;
          height: 17px;
          font-family: FontAwesome;
          margin-right: 5px;
        }
      }
      #sendForm {
        float: right;
      }
      .list-colleega {
        margin-bottom: 0;
      }
      .succes-message {
        clear: both;
        &.error {
          color: #f00;
        }
      }
    }
  }

  .succes {
    .form-field {
      opacity: 0.4;

      .checkbox {
        opacity: 0.5;
      }
    }

    .succes-message {
      margin-top: 35px;
      color: #00a9f0;
      font-size: rem-calc(18);
      font-weight: 300;
    }
  }
}
/* Test result */

.test-result,
.test-result-main {
  .form-form {
    input[type='text'] {
      font-family: Roboto;
      border-radius: 0;
      box-shadow: 0 0 0;
      border: 1px solid #e5e5e4;
      font-size: rem-calc(16);
      padding: 0 10px;
      margin-bottom: 0;
      &.error {
        border: 1px solid #f00;
      }
    }

    input[type='text']::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #9f9f9f;
    }

    input[type='text']::-moz-placeholder {
      /* Firefox 19+ */
      color: #9f9f9f;
    }

    input[type='text']:-ms-input-placeholder {
      /* IE 10+ */
      color: #9f9f9f;
    }

    input[type='text']:-moz-placeholder {
      /* Firefox 18- */
      color: #9f9f9f;
    }

    .form-controls {
      display: flex;

      input {
        &:nth-child(2) {
          border-left: 0;
        }
      }
    }

    .form-label {
      font-size: rem-calc(18);
      font-weight: 700;
    }

    .form-submit-button {
      margin-top: 35px;
      display: flex;
      justify-content: space-between;

      .button {
        background: #fff;
        color: #3c475e;
        font-size: rem-calc(18);
        font-weight: 300;
        border: 2px solid #3c475e;
        font-style: normal;
        border-radius: 6px;

        &:before {
          content: '\f055';
          display: inline-block;
          width: 17px;
          height: 17px;
          font-family: FontAwesome;
          margin-right: 5px;
        }
      }
    }
  }
}

.test-result-main {
  padding-top: 30px;

  h1 {
    font-family: Rubik;
    font-size: rem-calc(36);
    font-weight: 300;
  }

  .box-content {
    margin-bottom: 20px;
  }

  .sticky-new {
    padding: 30px;

    h2 {
      font-size: rem-calc(18);
      font-family: Rubik;
      padding-left: 60px;
      position: relative;
      margin-bottom: 20px;

      &:before {
        content: ' ';
        border-radius: 50%;
        background: #f2c900 url(../images/icon-sprites.png);
        display: inline-block;
        width: 47px;
        height: 47px;
        background-position: -38px -84px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .decs {
      font-weight: 300;
      font-size: rem-calc(18);
      color: #3c475e;
      margin-bottom: 15px;
    }

    .form-controls {
      input {
        border-top: 0;
      }

      &:first-child {
        input {
          border-top: 1px solid #e5e5e4;
        }
      }
    }

    .form-submit-button {
      margin-top: 35px;

      .board-widget__button {
        width: 100%;
        text-align: center;
      }
    }
  }

  .answer-list {
    list-style: none;
    > li {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .answer {
      /*background: #e5ffe5;*/
      font-size: rem-calc(18);
      font-weight: bold;
      padding: 0 20px 12px 20px;
      margin-bottom: 5px;
      &.wrong {
        /*background: #ffe5e5;*/
      }
    }
    .answer_title {
      border-left: $normal 1px solid;
      border-top: $normal 1px solid;
      border-right: $normal 1px solid;
      width: 100%;
      padding: 2px 7px;
      font-size: rem_calc(13);
      background-color: $normal;
      color: #fff;
      &.wrong {
        border-left: lighten($alert, 10%) 1px solid;
        border-right: lighten($alert, 10%) 1px solid;
        border-top: lighten($alert, 10%) 1px solid;
        background-color: lighten($alert, 10%);
      }
      &.success {
        border-left: $success 1px solid;
        border-right: $success 1px solid;
        border-top: $success 1px solid;
        background-color: $success;
      }
    }

    .result-label {
      color: #fff;
      padding: 5px 10px;
      font-size: rem-calc(14);

      &.fail {
        background-color: #f1816e;
      }

      &.true {
        background-color: #50b435;
      }

      &.right-answer {
        background-color: #3c475e;
      }
    }

    .expand {
      border: $normal 1px solid;
      color: $normal;
      font-size: rem-calc(18);
      padding: 8px 20px;
      margin: 0 0 5px 0;

      strong {
        font-size: rem-calc(36);
        font-weight: bold;
        position: relative;
        line-height: 1;
        margin-right: 20px;
        float: left;
      }
      .over {
        overflow: hidden;
        font-size: rem-calc(18);
        line-height: 24px;
        font-style: italic;
        padding: 8px 0;
        p:first-child {
          margin-bottom: 20px;
          font-style: normal;
        }
      }
      &.wrong {
        border: lighten($alert, 10%) 1px solid;
        color: lighten($alert, 10%);
      }
    }
  }
}

/* Keyframes for the fade-in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.header-not-top {
  .abonnementen {
    .header-page {
      .header-logo {
        @include breakpoint(1200px) {
          width: 160px;

          .header-logo__image {
            max-height: 50px;
          }
        }
      }
    }
  }
}
.abonnementen {
  .header-page {
    .header-logo {
      @include breakpoint(1200px) {
        width: 227px;

        .header-logo__image {
          max-height: 66px;
        }
      }
    }
  }
  .header-icons {
    @include breakpoint(1024px) {
      margin-right: 50px;
    }
  }
  .banner-row {
    position: relative;
    z-index: 3;
    height: 100%;
  }

  .banner-intro {
    @include breakpoint(769px) {
      height: 70vh;
    }
  }

  .banner-intro__inner {
    @include breakpoint(769px) {
      width: 60%;
      transform: translate(0%, -44%);
      position: absolute;
    }

    transform: translate(0%, 110px);
    text-align: left;
    left: 0;
    position: static;

    .banner-list {
      list-style: none;
      margin-top: 10px;

      li {
        font-family: Rubik;
        font-size: rem-calc(18px);
        font-weight: 400;
        margin-bottom: 5px;
        padding-left: 35px;
        position: relative;
        opacity: 0;
        opacity: 1 \9; /*just in case ie*/
        -webkit-animation: fadeIn ease-in 1;
        -moz-animation: fadeIn ease-in 1;
        animation: fadeIn ease-in 1;

        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;

        -webkit-animation-duration: 1s;
        -moz-animation-duration: 1s;
        animation-duration: 1s;

        &:before {
          content: ' ';
          width: 22px;
          height: 23px;
          background: url(../images/icon-sprites.png);
          background-position: 0 -198px;
          position: absolute;
          left: 0;
        }

        &:first-child {
          -webkit-animation-delay: 0.2s;
          -moz-animation-delay: 0.2s;
          animation-delay: 0.2s;
        }

        &:nth-child(2) {
          -webkit-animation-delay: 0.5s;
          -moz-animation-delay: 0.5s;
          animation-delay: 0.5s;
        }

        &:nth-child(3) {
          -webkit-animation-delay: 0.8s;
          -moz-animation-delay: 0.8s;
          animation-delay: 0.8s;
        }

        &:nth-child(4) {
          -webkit-animation-delay: 1.1s;
          -moz-animation-delay: 1.1s;
          animation-delay: 1.1s;
        }

        &:nth-child(5) {
          -webkit-animation-delay: 1.4s;
          -moz-animation-delay: 1.4s;
          animation-delay: 1.4s;
        }
      }
    }
  }

  .banner-intro__title {
    @include breakpoint(1024px) {
      font-size: rem-calc(55);
    }
  }

  .form-main-sticky {
    @include breakpoint(769px) {
      position: absolute;
      right: 0;
    }
  }

  .form-sticky {
    padding: 30px;
    background-color: #f1f1f1;

    h2 {
      font-size: rem-calc(18);
      font-family: Rubik;
      padding-left: 60px;
      position: relative;
      margin-bottom: 20px;

      &:before {
        content: ' ';
        border-radius: 50%;
        background: #f2c900 url(../images/icon-sprites.png);
        display: inline-block;
        width: 47px;
        height: 47px;
        background-position: 68px 13px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .form-text,
    .decs {
      font-weight: 300;
      font-size: rem-calc(18);
      color: #3c475e;
      margin-bottom: 15px;
    }

    .form-form {
      .field {
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
      }
      textarea,
      input[type='text'],
      select {
        font-family: Roboto !important;
        border-radius: 0 !important;
        box-shadow: 0 0 0 !important;
        border: 1px solid #e5e5e4 !important;
        font-size: rem-calc(16) !important;
        padding: 0 10px !important;
        margin-bottom: 0 !important;
        min-height: 50px;
        min-width: inherit !important;
      }
      textarea {
        height: 125px;
        padding: 10px !important;
      }
      .address,
      .dropdown {
        label {
          display: none;
        }
      }

      input[type='text']::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #9f9f9f !important;
      }

      input[type='text']::-moz-placeholder {
        /* Firefox 19+ */
        color: #9f9f9f !important;
      }

      input[type='text']:-ms-input-placeholder {
        /* IE 10+ */
        color: #9f9f9f !important;
      }

      input[type='text']:-moz-placeholder {
        /* Firefox 18- */
        color: #9f9f9f !important;
      }

      .form-controls {
        display: flex;
        float: none;

        input {
          &:nth-child(2) {
            border-left: 0;
          }
        }

        input {
          border-top: 0;
        }

        &:first-child {
          input {
            border-top: 1px solid #e5e5e4;
          }
        }

        select {
          border-left: 0;
          border-top: 0;
          width: 50% !important;
        }
      }

      .form-label {
        font-size: rem-calc(18);
        font-weight: 700;
      }

      .form-submit-button {
        margin-top: 35px;
        display: block;
        justify-content: space-between;

        .button {
          background: #fff;
          color: #3c475e;
          font-size: rem-calc(18);
          font-weight: 300;
          border: 2px solid #3c475e;
          font-style: normal;
          border-radius: 6px;

          &:before {
            content: '\f055';
            display: inline-block;
            width: 17px;
            height: 17px;
            font-family: FontAwesome;
            margin-right: 5px;
          }
        }
      }
      #field-76-container,
      #field-77-container {
        width: 50%;
        float: left;
        margin-bottom: 20px !important;
        input {
          border-top: 0 !important;
        }
      }
      #field-78-container {
        clear: both;
        display: inline-table;
        width: 100%;
      }
      #field-79-container {
        input {
          border-top: 0 !important;
        }
      }
      #field-81-container {
        margin-top: 20px;
      }
      .address {
        margin-top: 20px;
        .zipcode_check {
          display: block !important;
        }
        input {
          width: 50% !important;
          margin: 0 !important;
        }
        .clear {
          clear: both !important;
          display: block;
        }
        .city,
        .street {
          clear: both;
          display: inline-table;
          border-top: 0 !important;
        }
        .postcode,
        .street {
          border-right: 0 !important;
        }
        .add_on {
          display: none !important;
        }
      }
    }

    .form-submit-button {
      margin-top: 35px;
      width: 100%;
      input {
        width: 100%;
        border-radius: 5px !important;
        font-size: rem-calc(18);
        font-weight: 300;
        font-style: normal;
      }
      .board-widget__button {
        width: 100%;
        text-align: center;
      }
    }
  }

  .usp-block {
    @include breakpoint(769px) {
      padding-top: 0px;
    }
  }

  .abonnementen-main {
    padding-top: 40px;
    h2 {
      font-family: Rubik;
      font-size: rem-calc(36);
      font-weight: 300;
      margin-bottom: 30px;
    }

    p {
      font-size: rem-calc(18);
      margin-bottom: 20px;
      font-weight: 300;
    }

    .package {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 50px;

      .package-item {
        border: 5px solid #e2e4e7;
        padding: 25px;
        margin-bottom: 15px;

        @include breakpoint(768px) {
          width: 47%;
          margin-bottom: 0;
        }

        .title {
          text-align: center;
          font-family: Rubik;
          font-weight: 300;
          font-size: 30px;
          padding-bottom: 20px;
          border-bottom: 2px solid #e2e4e7;
        }

        ul {
          list-style: none;
          margin-bottom: 35px;

          li {
            margin-top: 35px;
          }

          .package-list {
            position: relative;
            padding-left: 40px;
            font-family: Rubik;
            font-size: rem-calc(18);
            font-weight: 500;
            margin-bottom: 10px;

            &:before {
              content: ' ';
              width: 22px;
              height: 23px;
              background: url(../images/icon-sprites.png);
              background-position: 0 -198px;
              position: absolute;
              left: 0;
            }
          }
        }

        .package-bottom {
          font-size: rem-calc(18);
          font-weight: bold;
          padding-top: 35px;
          border-top: 2px solid #e2e4e7;
        }
      }
    }
  }

  .search-block {
    display: block;
  }
}

/* artikelen page */
.qanda {
  #middle {
    padding: 145px 0 30px;
  }
  h1 {
    margin-bottom: 40px;
    font-family: $rubik-font;
    font-size: rem-calc(36);
    letter-spacing: 1px;
  }
  .accordion {
    padding: 23px 20px 18px;
    &__unlogged {
      .accordion-item {
        .accordion-content {
          padding-bottom: 225px;
          max-height: 425px;
          @include breakpoint(360px) {
            max-height: 395px;
          }
          @include breakpoint(420px) {
            max-height: 365px;
          }
        }
      }
    }
    @include breakpoint(768px) {
      padding: 23px 39px 18px;
    }
  }
  .accordion-item {
    &:first-child {
      .accordion-title {
        border-top: none;
      }
    }
    &:last-child {
      .accordion-title {
        border-bottom: none;
      }
      .accordion-content {
        border-top-width: 2px;
        padding-bottom: 0;
        border-bottom: none;
        blockquote {
          margin: 0;
          position: relative;
          display: block;
          color: $normal;
          padding: 0;
          font-size: rem-calc(18);
          line-height: rem-calc(24);
          font-weight: bold;
          font-style: italic;
          p {
            margin-bottom: 0;
          }
        }
        ul {
          padding: 0;
          margin: 0 0 0 40px;
        }
      }
    }
    @include between-breakpoints(100px, 767px) {
      border: none;
      //margin-bottom: 30px;
    }
    &.is-active {
      .accordion-title {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
    .accordion-title {
      border: #f5f5f6 2px solid;
      border-width: 1px 0;
      padding: 24px 0 27px;
      padding-right: 45px;
      font-weight: bold;
      color: $normal;
      font-size: rem-calc(18);
      position: relative;
      overflow: hidden;
      line-height: 23px;
      @include breakpoint(768px) {
        padding-right: 90px;
      }
      &:after {
        content: '';
        width: 12px;
        height: 9px;
        border-style: solid;
        border-width: 8px 7px 0 7px;
        border-color: #aeb9d4 transparent transparent transparent;
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        //display: none;
        @include breakpoint(768px) {
          display: block;
        }
      }
      span {
        width: 60px;
        height: 60px;
        line-height: 60px;
        display: inline-block;
        background: #e0cd00;
        text-align: center;
        margin-right: 20px;
        float: left;
        @include between-breakpoints(100px, 767px) {
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
      }
      &:hover,
      &:focus {
        background: none;
      }
      div {
        float: left;
        max-width: 190px;
        padding: 0px;
        @include breakpoint(371px) {
          max-width: 245px;
          padding: 0px;
        }
        @include breakpoint(450px) {
          max-width: 300px;
          padding: 10px;
        }
        @include breakpoint(1150px) {
          max-width: 410px;
          padding: 10px;
        }
      }
    }
    .accordion-content {
      position: relative;
      padding: 20px;
      font-size: rem-calc(18);
      line-height: rem-calc(25);
      border-top: 1px solid #f5f5f6;
      border-bottom: 1px solid #f5f5f6;
      p {
        margin-bottom: 15px;
        line-height: 28px;
      }
      @include breakpoint(1024px) {
        padding: 33px 0 20px;
      }
      strong,
      a {
        color: $normal;
        &:hover {
          color: #e0cd00;
        }
      }
    }
  }
  .accordion-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    &__in {
      background-color: #fff;
      //padding-bottom: 25px;
      padding-bottom: 47px;
    }
    &:before {
      content: '';
      display: block;
      height: 70px;
      background: linear-gradient(
        0deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    h3 {
      margin-bottom: 5px;
      font-size: 30px;
      font-weight: bold;
      line-height: 38px;
      text-align: center;
    }
    p {
      margin-bottom: 17px;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
    }
    a.button {
      color: #fff;
      cursor: pointer;
      padding: 11px 36px 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 21px;
      line-height: 27px;
      text-align: center;
      background-color: #00a9f0;
      border-radius: 4px;
      &:hover {
        color: #fff;
        background-color: darken(#00a9f0, 5%);
      }
    }
    .login-box {
      position: relative;
      width: 200px;
      margin: 0 auto;
      margin-top: 21px;
      padding-top: 3px;
      padding-left: 55px;
      text-align: left;
      font-size: 14px;
      line-height: 18px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 45px;
        height: 45px;
        background-color: #daeaf1;
        background-image: url(../images/icon-sprites.png);
        background-repeat: no-repeat;
        background-position: 17px -239px;
        border-radius: 50%;
      }
    }
  }
}
