$label_width: 160px + 15px;

.form-page-labels {
	margin: 0;
	overflow: hidden;
	li {
	    float: left;
	    font-size: 16px;
	    list-style: outside none none;
	    opacity: 0.5;
	    padding: 0 10px 0 0;
	    a {
	    	padding: 10px 15px;
	    	display: block;
	    	border: #ccc 1px solid;
	    	border-bottom: none;
	    	color: #000;
		    cursor: text;
		    text-decoration: none;
	    }
	    &.current {
	    	a {
	    		background: #f1f1f1; 
	    	}
	    }
	}
}

.form-form {
	.form-page {
	    width: 100%;
	}
	
	.field {
		margin-bottom: 10px;
		border: 0 none;
	    display: block;
	    position: relative;
	    padding-bottom: 0;
		&.textarea {
			label {
				padding-top: 0;
			}
		}
	}
	.form-controls, .field-input {
		float: none;
		overflow: hidden;
	}
	.form-label, .field-label {
	    padding-top: 5px;
	    width: 100%;
	}
	.form-buttons {
	    // padding-left: $label_width;
	}
	
	.form-option-label {
		display: block;
    	margin: 4px 0;
	}

	/* Style Elements */
	input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], 
	input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], 
	input[type="number"], input[type="search"], input[type="tel"], input[type="time"], 
	input[type="url"], input[type="color"], textarea {
		border-radius: 5px;
		box-shadow: inset 0 0 4px 0 rgba(60, 71, 94, 0.25);
		width: 100%;
		min-height: 50px;
		display: block;
		margin: 0 0 20px;
		transition: all 0.15s linear 0s;
    	width: 100%;
    	background: #fff;
    	&:disabled {
    		background: #f1f1f1;
			color: #b9b9b9;
    	}
	}
	textarea {
		min-height: 100px;
	}
	input[type="file"], input[type="checkbox"], input[type="radio"] {
		margin: 0 4px 4px 0;
	}
	label {
	    cursor: pointer;
	    display: block;
	    font-weight: normal;
	}
	 
	

	/* Validate */
	.validation-error {
		input, textarea {
	    	border-color: #f4242a;
	    }
	}
	.validation-success {
		input, textarea {
	    	border-color: #99c847;
	    }
	}
	.error-message {
	    color: #c03f43;
	    float: none;
	    font: 12px Arial;
	    margin-letf:$label_width;
	    white-space: nowrap;
	    margin-left: $label_width;
	    margin-bottom: 15px;
	}

	/* Override */
	.header {
		h1 {
			font-size: 25px;
		}
	}
	.form-radio {
		input[type="text"] {
			width: 40%;
		}
	}

	.full_name {
		.infix {
		    display: inline;
		    width: 70px !important;
		}
		.form-controls {
			input[type="text"] {
				display: inline;
			    min-width: 46px;
			    width: 215px;
			}
		}
	}

	.address{
		.street{
			width: 215px;
			float: left;
			margin: 0 4px 0 0;
		}
		.postcode,
		.add_on,
		.housenumber{
			float: left;
			margin: 0 4px 0 0;
		}
		.postcode{
			margin: 10px 4px 0 0;
			width: 100px;
		}
		.housenumber,
		.add_on{
			width: 90px !important;
		}
	}

	.dropdown select{
		height: 50px;
		font-size: 1.125rem;
		font-family: "colabobbol",sans-serif;
		padding: 10px;
		border-radius: 4px;
		border: #e2e2e2 1px solid;
		box-shadow: inset 0 2px 4px -1px rgba(0,0,0,0.1);
	}

	.form-buttons {
		// padding-left: $label_width; 
	}
	 
	.formbtn-success{
	 	background-color: #5BB75B;
		background-image: linear-gradient(center top , #62C462, #51A351);
	 }

	.files {
		.formbtn {
		    float: left;
		    margin-left: $label_width;
		    margin-top: -24px;
		    font-size: 14px;
		    border: none;
			color: #FFFFFF;
			text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
			border-radius: 5px;
			font-size: 16px;
			line-height: normal;
			padding: 5px 14px 7px;
			background-color: #62C462;
			background-image: linear-gradient(center top , #62C462, #51A351);
			background-image: -webkit-gradient(linear, left top, left bottom, from(#62C462), to(#51A351));
			background-image: -webkit-linear-gradient(top, #62C462, #51A351);
			box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
			cursor: pointer;
			display: inline-block;
			text-align: center;
			vertical-align: middle;
		    span {
		    	font-size: 14px;
		    }
			&.formbtn-warning{
				background-color: #ec5d59;
				background-image: linear-gradient(center top , #ec5d59, #bd362f);
				background-image: -webkit-gradient(linear, left top, left bottom, from(#ec5d59), to(#bd362f));
				background-image: -webkit-linear-gradient(top, #ec5d59, #bd362f);
			}
		}

		.fileName {
			padding: 0 10px;
		}
		.formFileQueu {
			li {
				display: block;
				overflow: hidden;
				margin-bottom: 10px;
			}
			.formbtn { 
				margin-top: 0;
				margin-bottom: 0;
				border: none;
			}

		}
	}

	/* Result block */
	div.result{
		.result-page{
			padding: 10px;
			border:1px solid #cccccc;
			margin: 10px 0;
		}
		.result-page > label{
			font-size: 20px;
			margin:5px 0;
			font-weight:bold;
		}
		.result_label{
			font-size: 15px;
			display: inline;
		}
		.result_value{
			font-size: 14px;
		}
		label{
			font-weight: bold;
		}
	}

}