.login,
.opem-main-menu {
    color: #fff;
    text-transform: uppercase;
    font-size: rem-calc(20);
    font-weight: bold;
    margin-top: 4px;
    margin-left: 25px;
    i {
        margin-left: 5px;
    }
    @include breakpoint(540px) {
        margin-top: 5px;
    }
}
.login{
    margin-top:0;
}

.dd-menu-wrap {
    display: none;
    position: absolute;
    top: 42px;
    right: -10px;
    background: #545f77;
    min-width: 310px;
    z-index: 50;
    @include breakpoint(540px) {
        top:40px;
    }
    @include breakpoint(768px) {
        min-width: 350px;
    }
    &:after {
        bottom: 100%;
        right: 11px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(241, 241, 241, 0);
        border-bottom-color: #545f77;
        border-width: 8px;
        margin-left: -8px;
    }
}
// .homepage{
//     // .opem-main-menu{
//     //     margin-top: 7px;
//     // }
//     .dd-menu-wrap{
//         top:38px;
//     }
// }
.menu-right {
    position: relative;
    @include breakpoint(540px) {
        min-width: 70%;
    }
    &.active {
        .dd-menu-wrap {
            display: block;
        }        
    }
}

.vertical.menu {
    > li {
        > a {
            font-size: rem-calc(18);
            font-weight: bold;
            color: #fff;
            border-top: #3c475e 1px solid;
        }
        &:first-child {
            > a {
                border-top: none;       
            }
        }
        .submenu {
            background: #3c475e;
            li a {
                border-top: #545f77 1px solid;
                font-weight: normal;
                padding-left: 30px;
            }
        }
    }
}